import {
  GET_USERS_IMPORT_PARSING,
  PARSE_USERS_IMPORT_FILE,
  SAVE_MAPPING_AND_PARSE,
  SYNC_IMPORT_USERS,
  UPDATE_IMPORT_APP_USERS_STATUS
} from '@root/constants'
import send from '@shared/redux-fetch'

export const createUsersImportParsing = ({ idOrg, idApp, idUpload }) => {
  return send(PARSE_USERS_IMPORT_FILE, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import`,
    method: 'POST',
    body: {
      idUpload
    }
  })
}

export const getUsersImportParsing = ({ idOrg, idApp, idUsersImportParsing }) => {
  return send(GET_USERS_IMPORT_PARSING, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import/${idUsersImportParsing}`
  })
}

export const getUsersImportPreviewChanges = ({ idOrg, idApp, idUsersImportParsing, idAppAccount }) => {
  return send(GET_USERS_IMPORT_PARSING, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import/${idUsersImportParsing}/previewChanges`,
    query: {
      idAppAccount
    }
  })
}

export const saveMappingAndParse = ({ idOrg, idApp, idUsersImportParsing, mapping, dateFormat }) => {
  return send(SAVE_MAPPING_AND_PARSE, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import/${idUsersImportParsing}/mapping`,
    method: 'PUT',
    body: {
      mapping,
      dateFormat
    }
  })
}

export const syncUsers = ({ idOrg, idApp, idUsersImportParsing }) => {
  return send(SYNC_IMPORT_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import/${idUsersImportParsing}/sync`,
    method: 'PUT',
    body: {}
  })
}

export const updateImportAppUsersStatus = importAppUsersStatus => ({
  type: UPDATE_IMPORT_APP_USERS_STATUS,
  payload: { importAppUsersStatus }
})
