import View from './view'
import { connect } from 'react-redux'
import { getCurrentApp } from '@selectors/apps'
import { toggleUploadExpenses } from '@shared/actions'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { getLastYearTotalExpenses } from '@selectors/transactions'

const mapStateToProps = (state, ownProps) => {
  const { expensesAggs } = ownProps
  const { app = EMPTY_OBJECT } = getCurrentApp(state) || EMPTY_OBJECT
  const { data: appExpenses = EMPTY_ARRAY, loading } = expensesAggs || EMPTY_OBJECT

  const expenses = (appExpenses || EMPTY_ARRAY)
    .slice()
    .map((expense, index, array) => {
      const prevMonth = index ? array[index - 1].total / 100 : 0
      const currMonth = expense.total / 100
      const diff = currMonth - prevMonth
      return {
        date: expense.date,
        value: expense.total,
        percentage: prevMonth ? ((currMonth - prevMonth) / prevMonth) : 0,
        diff,
        name: moment.utc(expense.date).format('MMM YYYY'),
        hadTransactions: expense.count > 0
      }
    })
  const total = getLastYearTotalExpenses(state) || 0
  const hadNoTransactions = expenses.length === 12 && expenses.every((expense) => expense.hadTransactions === false)
  return {
    loading: isEmpty(expenses) && loading,
    isInUse: !isEmpty(app),
    isAnyExpensesUploaded: Boolean(total),
    appName: app?.name,
    expenses,
    sources: app?.expensesSources,
    hadNoTransactions
  }
}

const mapDispatchToProps = {
  toggleUploadExpenses
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
