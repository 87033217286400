import React, { useEffect, useState } from 'react'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { IMPORT_USERS_COLUMNS, SharedProps, SharedState, DATE_FORMATS_OPTIONS } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { RadioButton, Spacer, Subtitle2 } from '@toriihq/design-system'
import Table from '@root/components/table'
import getColumns, { DONT_IMPORT_OPTION } from './columns'
import { TABLES } from '@root/constants'
import { IMPORT_FIELD_TITLES } from './types'
import { compact, isNil } from 'lodash'
import { DateFormatContainer, Option } from './styles'
import { getDateFormatsByStructure, getSelectedDateFormat, getSuggestedDateFormatsBasedOnValue } from '../../shared'
import Analytics from '@components/popups/importUsersPopup/analytics'

const MapColumns = ({
  onCancel, navigateToNextStep, navigateToBackStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const stepNameForAnalytics = 'Map columns'
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false)

  useEffect(() => {
    const emailFieldHasValue = !isNil(sharedState.previewDataMapping[IMPORT_USERS_COLUMNS.EMAIL])
    setIsNextButtonEnabled(emailFieldHasValue)
  }, [sharedState.previewDataMapping])

  const generatePreview = () => {
    const emailMapping = sharedState.previewDataMapping.email
    const licensesMapping = sharedState.previewDataMapping.licenses
    const lastVistTime = sharedState.previewDataMapping.lastVisitTime

    return [
      { id: IMPORT_USERS_COLUMNS.EMAIL, field: IMPORT_FIELD_TITLES.EMAIL, csvColumn: emailMapping, sample: sharedState.filePreview[emailMapping] },
      { id: IMPORT_USERS_COLUMNS.LICENSES, field: IMPORT_FIELD_TITLES.LICENSES, csvColumn: licensesMapping, sample: sharedState.filePreview[licensesMapping] },
      { id: IMPORT_USERS_COLUMNS.LAST_VISIT_TIME, field: IMPORT_FIELD_TITLES.LAST_USED_DATE, csvColumn: lastVistTime, sample: sharedState.filePreview[lastVistTime] }
    ]
  }

  const onSelectColumn = (field: IMPORT_USERS_COLUMNS, selection: { label: string, value: string }) => {
    let newSelectedColumn: IMPORT_USERS_COLUMNS | null = null
    let selectedColumns = sharedState.selectedColumnsToImport
    let suggestedDateFormat = sharedState.suggestedDateFormat
    let selectedDateFormat = sharedState.selectedDateFormat

    if (selection.value === DONT_IMPORT_OPTION.value) {
      selectedColumns = sharedState.selectedColumnsToImport.filter(column => column !== field)
    } else {
      newSelectedColumn = field
    }

    if (field === IMPORT_USERS_COLUMNS.LAST_VISIT_TIME) {
      const { originalSuggestions, filePreview } = sharedState
      const originalLastVisitTimeColumn = originalSuggestions?.suggestedMapping[IMPORT_USERS_COLUMNS.LAST_VISIT_TIME]

      if (selection.value === originalLastVisitTimeColumn) {
        suggestedDateFormat = originalSuggestions?.suggestedDateFormat
      } else {
        const currentSelectionSampleData = filePreview[selection.value]
        suggestedDateFormat = getSuggestedDateFormatsBasedOnValue(currentSelectionSampleData)
      }
      selectedDateFormat = getSelectedDateFormat(suggestedDateFormat)
    }

    setState({
      ...sharedState,
      selectedColumnsToImport: compact([
        ...selectedColumns,
        newSelectedColumn
      ]),
      previewDataMapping: {
        ...sharedState.previewDataMapping,
        [field]: selection.value
      },
      suggestedDateFormat,
      selectedDateFormat
    })
  }

  const fileHeaders = Object.keys(sharedState.filePreview)

  const chooseBetweenDateFormat = () => {
    const { suggestedDateFormat } = sharedState
    if (!suggestedDateFormat?.length || suggestedDateFormat.length < 2) {
      return null
    }
    const formatsByStructure = getDateFormatsByStructure(suggestedDateFormat)

    const onDateFormatChange = (selectedDateFormat: DATE_FORMATS_OPTIONS) => {
      const selectionToText = selectedDateFormat === DATE_FORMATS_OPTIONS.DAYS_PRIOR_MONTHS ? 'Day before month' : 'Month before day'
      Analytics.onDateFormatRadioButton(selectionToText)

      setState({ ...sharedState, selectedDateFormat: formatsByStructure[selectedDateFormat] })
    }

    const daysPriorMonthsFormat = formatsByStructure[DATE_FORMATS_OPTIONS.DAYS_PRIOR_MONTHS]
    const monthsPriorDaysFormat = formatsByStructure[DATE_FORMATS_OPTIONS.MONTHS_PRIOR_DAYS]

    return (
      <DateFormatContainer>
        <Subtitle2>How are dates formatted in your file?</Subtitle2>
        <Spacer top='space-200'>
          <Option>
            <RadioButton
              label={`Day precedes month (${daysPriorMonthsFormat})`}
              checked={sharedState.selectedDateFormat === daysPriorMonthsFormat}
              onChange={() => onDateFormatChange(DATE_FORMATS_OPTIONS.DAYS_PRIOR_MONTHS)}
            />
          </Option>
        </Spacer>
        <Spacer top='space-150'>
          <Option>
            <RadioButton
              label={`Month precedes day (${monthsPriorDaysFormat})`}
              checked={sharedState.selectedDateFormat === monthsPriorDaysFormat}
              onChange={() => onDateFormatChange(DATE_FORMATS_OPTIONS.MONTHS_PRIOR_DAYS)}
            />
          </Option>
        </Spacer>
      </DateFormatContainer>
    )
  }

  const onNextClick = () => {
    Analytics.onClickPreviewButton()
    if (sharedState.customParser) {
      setState({ ...sharedState, customParser: undefined })
    }
    navigateToNextStep()
  }

  const onCancelClick = () => {
    Analytics.onCloseImportPopupAnalytics(stepNameForAnalytics, 'Cancel')
    onCancel()
  }

  const onBackClick = () => {
    Analytics.onBackActionAnalytics(stepNameForAnalytics)
    navigateToBackStep()
  }

  return (
    <>
      <ToriiPopup.Header header='Map columns' />
      <ToriiPopup.Content>
        <Subtitle2>Map columns to their corresponding fields</Subtitle2>
        <Table
          tableKey={TABLES.importUsersColumnMapping.key}
          columns={getColumns(fileHeaders, sharedState, onSelectColumn)}
          data={generatePreview()}
          style={{ minHeight: '200px', borderTop: 'none' }}
          sortable={false}
          draggable={false}
        />
        <Spacer top='space-400'>
          {chooseBetweenDateFormat()}
        </Spacer>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonAction={onCancelClick}
        cancelButtonText='Cancel'
        mainButtonText='Next'
        mainButtonAction={onNextClick}
        isMainButtonDisabled={!isNextButtonEnabled}
        showBackButton
        backButtonAction={onBackClick}
      />
    </>
  )
}

export default MapColumns
