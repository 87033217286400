import React, { useContext, useEffect, useRef, useState } from 'react'
import { css } from 'glamor'
import * as Styles from './styles'
import { CONTRACT_TABLE_HEADERS, DATE_FORMATS_OPTIONS, READ_ONLY_CONTRACT_FIELDS, UPLOAD_TYPES } from '@root/constants'
import { useDispatch, useSelector } from 'react-redux'
import {
  uploadFile,
  toggleImportContracts,
  getContracts,
  getContractsFields,
  getContractsGroups,
  getParsedContract,
  parseContract
} from '@shared/actions'
import { getContractsFields as getContractsFieldsSelector } from '@selectors/contracts'
import { getIdOrg } from '@selectors/org'
import Analytics from '@helpers/analytics'
import pluralize from 'pluralize'
import { exportCSV } from '@helpers/exportCSV'
import exportExcel from '@root/helpers/exportXLSX'
import moment from 'moment'
import ToriiSelect from '@components/select'
import { fontWeight } from '@shared/style/sizes'
import PropTypes from 'prop-types'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2'
import ImportSuccess from '@media/import_success.svg'
import { Button, ButtonType, ButtonSize, Link, UploadArea } from '@toriihq/design-system'

const VALID_FILE_TYPES = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
const MANDATORY_HEADERS = [CONTRACT_TABLE_HEADERS.name, CONTRACT_TABLE_HEADERS.appName, CONTRACT_TABLE_HEADERS.status, CONTRACT_TABLE_HEADERS.currency]

const DROP_ZONE_DEFAULT = 'default'
const DROP_ZONE_UPLOADING = 'uploading'
const DROP_ZONE_SUCCESS = 'success'
const DROP_ZONE_UPLOAD_ERROR = 'upload_error'
const DROP_ZONE_PARSE_ERROR = 'parse_error'

const POLL_STATUS_INTERVAL_IN_SECONDS = 5

export const CONTRACT_PARSE_STATUS = {
  parsingInProgress: 'parsing_in_progress',
  parsingSuccessfully: 'parsing_successfully',
  parsingFailed: 'parsing_failed'
}

const IMPORT_SUMMARY_FIELDS = {
  skippedContracts: 'skippedContracts',
  skippedFields: 'skippedFields',
  others: 'others'
}

const importSummaryText = {
  [IMPORT_SUMMARY_FIELDS.skippedContracts]: (importSummary) => `Skipping ${importSummary[IMPORT_SUMMARY_FIELDS.skippedContracts].length} ${pluralize('contract', importSummary[IMPORT_SUMMARY_FIELDS.skippedContracts].length)}`,
  [IMPORT_SUMMARY_FIELDS.skippedFields]: (importSummary) => `Skipping ${importSummary[IMPORT_SUMMARY_FIELDS.skippedFields].length} ${pluralize('fields', importSummary[IMPORT_SUMMARY_FIELDS.skippedFields].length)} in valid contracts`,
  [IMPORT_SUMMARY_FIELDS.others]: (importSummary) => `Skipping ${importSummary[IMPORT_SUMMARY_FIELDS.others].length} ${pluralize('others', importSummary[IMPORT_SUMMARY_FIELDS.others].length)}`
}

export const ImportContracts = (props) => {
  const [dropZone, setDropZone] = useState(DROP_ZONE_DEFAULT)
  const [selectedDateFormat, setSelectedDateFormat] = useState('')
  const [totalContracts, setTotalContracts] = useState(0)
  const [updatedContracts, setUpdatedContracts] = useState(0)
  const [addedContracts, setAddedContracts] = useState(0)
  const [importSummary, setImportSummary] = useState({})
  const [detailsState, setDetailsState] = useState({ skippedContracts: false, skippedFields: false, others: false })
  const [errorMessage, setErrorMessage] = useState('')
  const fileRef = useRef(null)

  const templateHeaders = useSelector(getContractsFieldsSelector)
    .filter(field => field.type !== 'fileUpload' && !['name', 'status'].includes(field.systemKey) && !READ_ONLY_CONTRACT_FIELDS.includes(field.systemKey))
    .map(field => field.name)

  const idOrg = useSelector(getIdOrg)

  const dispatch = useDispatch()

  const { mainButtonAction } = useContext(ToriiPopupContext)

  const idUpload = useRef(null)
  const interval = useRef(null)

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])

  const onSetDropZone = (dropZone) => {
    const { onIsError, onShowFooter } = props

    setDropZone(dropZone)
    onShowFooter([DROP_ZONE_SUCCESS].includes(dropZone))
    onIsError([DROP_ZONE_PARSE_ERROR, DROP_ZONE_UPLOAD_ERROR].includes(dropZone))
  }

  const onUploadFile = async (file) => {
    try {
      onSetDropZone(DROP_ZONE_UPLOADING)
      const response = await dispatch(uploadFile({ idOrg, file, type: UPLOAD_TYPES.CONTRACT }))
      idUpload.current = response.idUpload
    } catch (e) {
      Analytics.track('Upload contracts file failed', {
        name: file.name,
        'Error description': e.message
      })
      setErrorMessage(e.message)
      onSetDropZone(DROP_ZONE_UPLOAD_ERROR)
    }
    try {
      const { idContractParsing } = await dispatch(parseContract({
        idOrg,
        idUpload: idUpload.current,
        dateFormat: selectedDateFormat,
        dryRun: true
      }))

      interval.current = setInterval(async () => {
        const response = await dispatch(getParsedContract({ idOrg, idContractParsing }))
        const { parsingStatus, totalContracts, updatedContracts, addedContracts, summary: importSummary, errorMessage: responseErrorMessage } = response
        if (parsingStatus === CONTRACT_PARSE_STATUS.parsingSuccessfully) {
          clearInterval(interval.current)
          interval.current = null
          Analytics.track('Import contracts file succeeded', {
            name: file.name,
            totalContracts,
            updatedContracts,
            addedContracts
          })
          onSetDropZone(DROP_ZONE_SUCCESS)
          setTotalContracts(totalContracts)
          setUpdatedContracts(updatedContracts)
          setAddedContracts(addedContracts)
          setImportSummary(importSummary)
        }
        if (parsingStatus === CONTRACT_PARSE_STATUS.parsingFailed) {
          clearInterval(interval.current)
          interval.current = null
          Analytics.track('Import contracts file failed (parsing error)', {
            name: file.name,
            type: file.type
          })
          onSetDropZone(DROP_ZONE_PARSE_ERROR)
          setErrorMessage(responseErrorMessage)
        }
      }, POLL_STATUS_INTERVAL_IN_SECONDS * 1000)
    } catch (e) {
      console.error(e)
      Analytics.track('Import contracts file failed (parsing error)', {
        name: file.name,
        type: file.type
      })
      onSetDropZone(DROP_ZONE_PARSE_ERROR)
    }
  }

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    onSetDropZone(DROP_ZONE_UPLOADING)
    if (rejectedFiles.length) {
      Analytics.track('Upload contracts file failed (invalid file type)', {
        name: rejectedFiles[0].file.name,
        type: rejectedFiles[0].file.type
      })
      setErrorMessage('Invalid file type. You can only upload CSV/XLSX files.')
      return onSetDropZone(DROP_ZONE_UPLOAD_ERROR)
    }

    const file = acceptedFiles[0]
    Analytics.track('Upload contracts file', {
      name: file.name
    })

    fileRef.current = file
    await onUploadFile(file)
  }

  const closePopup = () => {
    dispatch(toggleImportContracts(false, true))
  }

  const postUpload = async () => {
    if (dropZone === DROP_ZONE_UPLOAD_ERROR) {
      await onUploadFile(fileRef.current)
    } else {
      const { idContractParsing } = await dispatch(parseContract({
        idOrg,
        idUpload: idUpload.current,
        dateFormat: selectedDateFormat,
        dryRun: dropZone === DROP_ZONE_PARSE_ERROR
      }))

      onSetDropZone(DROP_ZONE_UPLOADING)

      if (!interval.current) {
        interval.current = setInterval(async () => {
          const response = await dispatch(getParsedContract({ idOrg, idContractParsing }))
          const { parsingStatus = CONTRACT_PARSE_STATUS.parsingInProgress } = response
          if (parsingStatus !== CONTRACT_PARSE_STATUS.parsingInProgress) {
            clearInterval(interval.current)
            interval.current = null
            if (parsingStatus === CONTRACT_PARSE_STATUS.parsingSuccessfully) {
              await Promise.all([dispatch(getContracts({ idOrg })), dispatch(getContractsFields({ idOrg })), dispatch(getContractsGroups({ idOrg }))])
            }
            closePopup()
          }
        }, POLL_STATUS_INTERVAL_IN_SECONDS * 1000)
      }
    }
  }

  const renderDropZone = () => {
    const disabled = !selectedDateFormat
    mainButtonAction.current = postUpload

    const state = dropZone === DROP_ZONE_UPLOADING ? 'loading' : [DROP_ZONE_UPLOAD_ERROR, DROP_ZONE_PARSE_ERROR].includes(dropZone) ? 'error' : 'neutral'
    return (
      <>
        <div {...css({ marginBottom: 10 })}>1. Choose the date format:</div>
        <ToriiSelect
          options={DATE_FORMATS_OPTIONS}
          value={selectedDateFormat}
          onChange={onChange}
          clearable={false}
          disabled={dropZone === DROP_ZONE_UPLOADING}
        />
        <div {...css({ margin: '20px 0 10px' })}>
          <span>2. Upload your XLSX/CSV or use our </span>
          <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onDownloadEXCELTemplate} label='Excel template' recoveryTime={0} />
          <span> or </span>
          <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onDownloadCSVTemplate} label='CSV template' recoveryTime={0} />
        </div>
        <UploadArea
          onFileSelect={onDrop}
          validFileTypes={VALID_FILE_TYPES}
          disabled={disabled}
          multiple={false}
          loadingMessage='Crunching the numbers...'
          state={state}
          errorMessage={errorMessage || 'Failed to parse file.'}
        />
        <div {...css({ marginTop: 20 })}>
          <div {...css({ fontWeight: fontWeight.semiBold })}>No contracts will be deleted from Torii during the
            import.
          </div>
          <div>Learn about importing contracts <Link href='https://support.toriihq.com/hc/en-us/articles/5150804779291' target='_blank'>here</Link>.
          </div>
        </div>
      </>
    )
  }

  const onDetailsClick = (key) => {
    setDetailsState({ ...detailsState, [key]: !detailsState[key] })
  }

  const renderSkippedDetails = (key) => {
    if (!importSummary || !importSummary[key] || importSummary[key].length === 0) {
      return null
    }

    const text = importSummaryText[key](importSummary)

    return <div {...Styles.SummaryContainer}>
      <div {...css(Styles.DarkText, Styles.ShowOrHideContainer)}>
        {text} - {' '} <Button type={ButtonType.compact} size={ButtonSize.small}
          onClick={() => onDetailsClick(key)} label={`${detailsState[key] ? 'Hide' : 'Show'} details`}
          icon={detailsState[key] ? 'ChevronUp' : 'ChevronDown'} />
      </div>
      {detailsState[key] ? <div {...Styles.DetailsContainer}>
        {importSummary[key].map((row, index) => <div key={`${key}-${index}`} {...Styles.DetailsRow}><strong>Row #{row.rowIndex}</strong>: {row.message}
        </div>)}
      </div> : null}
    </div>
  }

  const renderPostUploadSuccess = () => {
    return (
      <div {...Styles.FlexColumn}>
        <span {...Styles.IconContainer}>
          <img height={78} width={99} src={ImportSuccess} alt='import-successful' />
        </span>
        <div>
          <div {...Styles.Text}>Importing {updatedContracts + addedContracts} out
            of {totalContracts} {pluralize('contract', totalContracts)}</div>
          {updatedContracts
            ? <div {...css(Styles.DarkText, Styles.TextSpacingTop, !addedContracts && Styles.TextSpacingBottom)}>Updating {updatedContracts} existing {pluralize('contract', updatedContracts)}</div> : null}
          {addedContracts
            ? <div {...css(Styles.DarkText, Styles.TextSpacingTop, Styles.TextSpacingBottom)}>Adding {addedContracts} new {pluralize('contract', addedContracts)}</div> : null}
          {renderSkippedDetails(IMPORT_SUMMARY_FIELDS.skippedContracts)}
          {renderSkippedDetails(IMPORT_SUMMARY_FIELDS.skippedFields)}
          {renderSkippedDetails(IMPORT_SUMMARY_FIELDS.others)}
        </div>
      </div>
    )
  }

  const onChange = (selectedOptions) => {
    setSelectedDateFormat(selectedOptions.value)
  }

  const onDownloadCSVTemplate = () => {
    exportCSV(`torii_contracts_template_${moment().format('DD_MMMM_YYYY')}.csv`, [], [...MANDATORY_HEADERS, ...templateHeaders], { disableCommaSeparator: true })
  }

  const onDownloadEXCELTemplate = async () => {
    await exportExcel(`torii_contracts_template_${moment().format('DD_MMMM_YYYY')}.xlsx`, [], [...MANDATORY_HEADERS, ...templateHeaders], [...MANDATORY_HEADERS, ...templateHeaders], '')
  }

  const renderBody = () => {
    return (
      <div {...Styles.BodyDescription}>
        {(() => {
          switch (dropZone) {
            case DROP_ZONE_DEFAULT:
            case DROP_ZONE_UPLOADING:
            case DROP_ZONE_UPLOAD_ERROR:
            case DROP_ZONE_PARSE_ERROR:
              return renderDropZone()
            case DROP_ZONE_SUCCESS:
              return renderPostUploadSuccess()
            default:
              return null
          }
        })()}
      </div>
    )
  }

  const { style } = props

  return (
    <div style={style}>
      {renderBody()}
    </div>
  )
}

ImportContracts.propTypes = {
  idOrg: PropTypes.number,
  uploadFile: PropTypes.func.isRequired,
  parseContract: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
  getContractsFields: PropTypes.func.isRequired,
  getContractsGroups: PropTypes.func.isRequired,
  toggleImportContracts: PropTypes.func.isRequired,
  templateHeaders: PropTypes.array,
  style: PropTypes.object
}
export default ImportContracts
