import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Avatar, Icon, Menu as DSMenu } from '@toriihq/design-system'
import colors from '../../../shared/style/colors'
import { clearButton } from '../../../shared/style/mixins'
import RelativeTeamLink from '../../relativeTeamLink'
import onClickOutside from 'react-onclickoutside'

const CSS = {
  main: css({
    position: 'relative'
  }),
  button: css(clearButton, {
    display: 'flex',
    alignItems: 'center',
    color: colors.black,
    height: '36px',
    padding: '0 6px',
    borderRadius: '4px',
    transition: 'background .2s',
    ':hover': {
      background: colors.lightBlue5,
      color: colors.blue,
      cursor: 'pointer'
    },
    ':focus': {
      background: colors.lightBlue5,
      color: colors.blue
    },
    ':active': {
      boxShadow: `0 0 0 2px ${colors.lightBlue5}`,
      background: colors.lightBlue5
    }
  }),
  ul: css({
    position: 'absolute',
    right: 0,
    top: '41px',
    width: '170px',
    padding: '10px 0',
    backgroundColor: colors.white,
    color: colors.black,
    boxShadow: `0px 0px 20px ${colors.lightBlue6}`,
    border: `1px solid ${colors.border}`,
    borderRadius: '5px',
    listStyle: 'none',
    margin: 0,
    opacity: 0,
    transform: 'translateY(-5px)',
    transition: 'opacity .2s, transform .1s',
    visibility: 'hidden',
    ' > li': {
      height: '30px',
      lineHeight: '25px',
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color .2s',
      ':hover': {
        backgroundColor: colors.background
      },
      ' > a': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        paddingLeft: 16,
        lineHeight: '20px'
      }
    }
  }),
  isOpen: css({
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)'
  }),
  logOutIcon: css({
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }),
  arrowIcon: css({
    display: 'flex',
    marginLeft: 4,
    transition: 'transform 0.2s ease-in-out'
  }),
  flip: css({
    transform: 'rotate(-180deg)'
  })
}

class Menu extends Component {
  state = {
    isOpen: false
  }

  handleClickOutside = () => {
    this.setState({ isOpen: false })
  }

  onClick = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  render () {
    const { isOpen } = this.state
    const { firstName, lastName, photoUrl } = this.props
    return (
      <DSMenu
        isOpen={isOpen}
        align='end'
        items={[
          {
            type: 'item',
            element: <DSMenu.Item>
              <RelativeTeamLink to='/settings/notifications'>
                Notifications settings
              </RelativeTeamLink>
            </DSMenu.Item>
          },
          {
            type: 'item',
            element: <DSMenu.Item>
              <a href='/login/logout'>
                <span {...CSS.logOutIcon}>
                  <Icon name='Logout' color='primary' />
                  Log out
                </span>
              </a>
            </DSMenu.Item>
          }
        ]}>
        <button {...css(CSS.button, isOpen && { background: colors.lightBlue5, color: colors.blue })}
          onClick={this.onClick}>
          <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
          <span {...css(CSS.arrowIcon, isOpen && CSS.flip)}>
            <Icon name='ChevronDown' color='inherit' />
          </span>
        </button>
      </DSMenu>
    )
  }
}

Menu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string
}

export default onClickOutside(Menu)
