import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from '../placeholder'
import AppIconWithBadge from '../appIconWithBadge'
import { H3, Tooltip, Icon, Spacer, Popover, Stack, Subtitle2, Body2, Link } from '@toriihq/design-system'
import SelectState from '../selectState'
import UsageIcon from '../usageIcon'
import { SCOPES, DATE_FORMAT, EXPENSES_RANGE_OPTIONS, EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS } from '@root/constants'
import * as Style from './style'
import { formatNumber } from '@root/shared/utils'
import UsersStatusesBreakdown from './usersStatusesBreakdown'
import Currency from '@components/currency'
import RelativeTeamLink from '@components/relativeTeamLink'
import moment from 'moment'
import Analytics from './analytics'

const showExpenses = (loading, last12MonthExpenses) => (
  <div {...Style.section}>
    <div {...Style.sectionTitle}>
      Expenses
      <Spacer left={'space-100'}>
        <Tooltip label='Last 12 months'>
          <Icon name='Info' />
        </Tooltip>
      </Spacer>
    </div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        <Currency value={last12MonthExpenses} />
      </div>
    </Placeholder>
  </div>
)

const showLicenses = (loading, numberOfAssignedLicenses, numberOfUnassignedLicenses, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated, name) => {
  const tooltipLabel = `This number does not take into account unassigned licenses since the ${name} integration does not support providing this data. To get the unassigned license count, please go the the Licenses page and enter the total purchased licenses.`
  return (
    <div {...Style.section}>
      <div {...Style.sectionTitle} >
        Licenses
        {isAppTotalAmountPartialCalculated && <Spacer left={'space-100'}>
          <Tooltip label={tooltipLabel}>
            <Icon name='Info' />
          </Tooltip>
        </Spacer>}
      </div>
      <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
        <div {...Style.sectionBody}>
          {formatNumber(numberOfAssignedLicenses + numberOfUnassignedLicenses)}

        </div>
        { isTotalAmountEnteredByUser && <div {...Style.sectionDescription}>edited</div>}
      </Placeholder>
    </div>
  )
}

const showActiveContractsDetails = ({ loading, activeContractsTotalValue, hasExpensesAccess, hasExpensesAndActiveContractsDuration, expensesInActiveContractTerm, activeContractTerm, expensesComparedToActiveContractValue, idApp, name }) => (
  <div {...Style.section} style={{ minWidth: '215px' }} >
    <div {...Style.sectionTitle}>Active contracts value</div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        <Currency value={activeContractsTotalValue} />
      </div>

      { hasExpensesAccess && Boolean(expensesInActiveContractTerm || hasExpensesAndActiveContractsDuration) &&
          expensesInActiveContractDuration({ expensesInActiveContractTerm, activeContractsTotalValue, activeContractTerm, expensesComparedToActiveContractValue, idApp, name }) }

    </Placeholder>
  </div>
)

const expensesInActiveContractDuration = ({ expensesInActiveContractTerm, activeContractsTotalValue, activeContractTerm, expensesComparedToActiveContractValue, idApp, name }) => {
  const shouldWarn = expensesComparedToActiveContractValue > 10
  const popoverContent = expensesInActiveContractDurationPopoverContent({ activeContractTerm, idApp, name })

  return <div {...Style.sectionDescription}>
    <span {...(shouldWarn ? Style.warningText : {})} >
      <Currency value={expensesInActiveContractTerm} />
    </span>&nbsp;Expenses
    <Spacer left={'space-100'}>
      <Popover showArrow position='bottom' maxWidth={300} content={popoverContent}>
        <div onMouseEnter={() => Analytics.onHoverExpenseInActiveContractDurationPopup(name)} >
          <Icon name='Info' />
        </div>
      </Popover>
    </Spacer>
  </div>
}

const expensesInActiveContractDurationPopoverContent = ({ activeContractTerm, idApp, name }) => {
  const { earliestStartDate, latestEndDate } = activeContractTerm
  const activeContractTermSelectValue = EXPENSES_TABLE_SECONDARY_CUSTOM_SELECT_OPTIONS[EXPENSES_RANGE_OPTIONS.ACTIVE_CONTRACT_TERM]

  return <Stack gap='space-200'>
    <Stack gap='space-050'>
      <Subtitle2>Expenses in active contract duration</Subtitle2>
      <Body2>The sum of all expenses that occurred during the active contracts term.</Body2>
      <RelativeTeamLink to={`/app/${idApp}/expenses`} search={`secondaryCustomSelectSelectedValue=${JSON.stringify(activeContractTermSelectValue)}`} onClick={() => Analytics.clickOnViewExpensesInContractDuration(name)}>
        <Link>View expenses in contract duration</Link>
      </RelativeTeamLink>
    </Stack>
    <Stack gap='space-050'>
      <Subtitle2>Active contract duration</Subtitle2>
      <Body2>{moment(earliestStartDate).format(DATE_FORMAT)} - {moment(latestEndDate).format(DATE_FORMAT)}</Body2>
      <RelativeTeamLink to={`/app/${idApp}/contracts`} search={`filters=${JSON.stringify([{ key: 'status', op: 'equals', value: { value: 'active' } }])}`} onClick={() => Analytics.clickOnViewActiveContracts(name)}>
        <Link>View active contracts</Link>
      </RelativeTeamLink>
    </Stack>
  </Stack>
}

const showUsers = (
  loading,
  numberOfUsers,
  isConnected,
  connectedAppUsersStatusesBreakdown,
  integrationDocumentationLink
) => {
  const isStatusesBreakdownAvailable =
    isConnected && connectedAppUsersStatusesBreakdown?.allAppAccountsBreakdown

  const usersCountBadge = (<div {...Style.section}>
    <div {...Style.sectionTitle}>Users
      {isStatusesBreakdownAvailable && <Spacer left={'space-100'}>
        <Icon name='ChartPie' />
      </Spacer>}
    </div>
    <Placeholder
      loading={loading} type='rect'
      style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        {formatNumber(numberOfUsers)}
      </div>
    </Placeholder>
  </div>)

  if (!isStatusesBreakdownAvailable) {
    return usersCountBadge
  }

  return <div {...Style.section}>
    <Popover
      position='bottom'
      align='center'
      showArrow
      content={<UsersStatusesBreakdown
        integrationDocumentationLink={integrationDocumentationLink}
        connectedAppUsersStatusesBreakdown={connectedAppUsersStatusesBreakdown} />}
    >
      {usersCountBadge}
    </Popover>
  </div>
}

const showState = (isInUse, loading, stateInfo) => (
  <div {...Style.section} style={{ marginLeft: 'auto' }}>
    <div {...Style.sectionTitle}>State</div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '160px', maxWidth: '160px' }}>
      {isInUse &&
      <SelectState
        {...stateInfo}
        allowedScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}
        pageName='App page'
      />}
    </Placeholder>
  </div>
)

const AppPageHeader = ({
  loading,
  name,
  imageUrl,
  idApp,
  score,
  numberOfUsers,
  numberOfAssignedLicenses,
  numberOfUnassignedLicenses,
  isTotalAmountEnteredByUser,
  isAppTotalAmountPartialCalculated,
  isCustom,
  isInUse,
  isCore,
  last12MonthExpenses,
  activeContractTerm,
  expensesInActiveContractTerm,
  hasExpensesAndActiveContractsDuration,
  expensesComparedToActiveContractValue,
  stateInfo,
  lastVisitTime,
  showLicenseNumber,
  activeContractsTotalValue,
  isContractsVisible,
  hasExpensesAccess,
  isConnected,
  connectedAppUsersStatusesBreakdown,
  integrationDocumentationLink
}) => (
  <div {...Style.main}>
    <div {...Style.header}>
      <Placeholder loading={loading} type='rect' style={{ height: '50px', width: '50px' }}>
        <AppIconWithBadge name={name} src={imageUrl} badge={Boolean(isCustom) && 'CUSTOM'} size='x-large' />
      </Placeholder>
      <Spacer left='space-300'>
        <Placeholder loading={loading} type='rect' style={{ height: '25px', width: '150px' }}>
          <>
            <Spacer right='space-200'><H3>{name}</H3></Spacer>
            <UsageIcon
              score={score}
              width={6}
              initialHeight={10}
              diffHeight={4}
              margin={3}
              tooltipText='on average'
              lastVisitTime={lastVisitTime}
              isCore={isCore}
            />
          </>
        </Placeholder>
      </Spacer>
    </div>

    {(isInUse || loading) && (
      <div {...Style.details}>
        {showLicenseNumber && showLicenses(loading, numberOfAssignedLicenses, numberOfUnassignedLicenses, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated, name)}
        {showUsers(loading, numberOfUsers, isConnected, connectedAppUsersStatusesBreakdown, integrationDocumentationLink)}
        {hasExpensesAccess && (last12MonthExpenses || loading) && showExpenses(loading, last12MonthExpenses)}
        {isContractsVisible && Boolean(activeContractsTotalValue) &&
          showActiveContractsDetails({ loading, activeContractsTotalValue, hasExpensesAccess, hasExpensesAndActiveContractsDuration, expensesInActiveContractTerm, activeContractTerm, expensesComparedToActiveContractValue, idApp, name })}
        {showState(isInUse, loading, stateInfo)}
      </div>
    )}
  </div>
)

AppPageHeader.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  score: PropTypes.number,
  url: PropTypes.string,
  description: PropTypes.string,
  isCustom: PropTypes.number,
  isInUse: PropTypes.bool,
  loading: PropTypes.bool,
  stateInfo: PropTypes.object,
  numberOfUsers: PropTypes.number,
  totalExpenses: PropTypes.number,
  usersTooltip: PropTypes.string,
  activeContractsTotalValue: PropTypes.number,
  isContractsVisible: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool,
  connectedAppUsersStatusesBreakdown: PropTypes.object,
  integrationDocumentationLink: PropTypes.string
}

AppPageHeader.defaultProps = {}

export default AppPageHeader
