import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { Counter, Icon, ExpandableSection, Subtitle2, Spacer, AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import Table from '@root/components/table'
import { AlertBoxWrapper, ExpandableSectionWrapper } from './style'
import pluralize from 'pluralize'
import { useDispatch } from 'react-redux'
import { syncUsers } from '@root/shared/actions/usersImport'
import Analytics from '@components/popups/importUsersPopup/analytics'
import { invalidUsersColumns, missingUsersColumns, validUsersColumns } from './columns'
import { startCase } from 'lodash'

const ReviewImportChanges = ({
  onCancel, navigateToNextStep, navigateToBackStep, sharedState, sharedProps
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const stepNameForAnalytics = 'Preview'
  const { isFirstTimeImport, appName } = sharedProps
  const { parsingPreviewChanges, customParser } = sharedState
  const { invalidUsers, validUsers, missingUsers } = parsingPreviewChanges
  const dispatch = useDispatch()

  const renderPopupTable = ({ columns, data }) => {
    return (
      <Table
        data={data}
        columns={columns}
        style={{ minHeight: 'unset', borderTop: 'none' }}
        tableHeaderStyle={{ padding: 0 }}
        draggable={false}
      />)
  }

  const renderInvalidUsersExpandable = () => {
    return invalidUsers.length ? (
      <ExpandableSectionWrapper>
        <ExpandableSection
          key={'invalidUsers'}
          title={<>Users with errors<Counter value={invalidUsers.length} /><Icon name='Alert' color='warning' /></>}
          subtitle={'These users will be skipped'}
          onToggle={() => Analytics.onClickSkipUsersPanel()}>
          {renderPopupTable({ columns: invalidUsersColumns, data: invalidUsers })}
        </ExpandableSection>
      </ExpandableSectionWrapper>
    ) : null
  }

  const renderMissingUsersExpandable = () => {
    return missingUsers.length ? (
      <ExpandableSectionWrapper>
        <ExpandableSection
          key={'missingUsers'}
          title={<>Users that will be removed<Counter value={missingUsers.length} /></>}
          subtitle={'These users will be removed from the app user list '}
          onToggle={() => Analytics.onClickMissingUsersPanel()}>
          {renderPopupTable({ columns: missingUsersColumns, data: missingUsers })}
        </ExpandableSection>
      </ExpandableSectionWrapper>
    ) : null
  }

  const renderValidUsersTable = () => {
    const { customParser, selectedColumnsToImport } = sharedState
    const { columnsToImport: parserColumnsToImport, uiConfig } = customParser || {}
    const topSpace = [...invalidUsers, ...missingUsers].length > 0 ? 'space-200' : 'space-0'
    const columnsToShow = parserColumnsToImport || selectedColumnsToImport
    const columnTooltips = uiConfig?.columnTooltips || {}

    const columns = validUsersColumns({ columnsToShow, isFirstTimeImport, columnTooltips })

    return validUsers.length ? (
      <>
        <Spacer top={topSpace} bottom='space-150'>
          <Subtitle2>{pluralize('user', validUsers.length, true)} will be added{!isFirstTimeImport ? ' or updated' : null}</Subtitle2>
        </Spacer>
        {renderPopupTable({ columns, data: validUsers })}
      </>
    ) : null
  }

  const customParserAlertBox = () => {
    const onMapColumnsManuallyClick = () => {
      navigateToBackStep(2)
    }

    return customParser ? (
      <AlertBoxWrapper>
        <AlertBox
          type={AlertBoxType.POSITIVE}
          title={`File was identified as a standard ${startCase(appName)} user report and was automatically parsed by Torii.`}
          description={<><Link onClick={onMapColumnsManuallyClick}>Manually map columns</Link> if the outcome is not what you expected.</>}
        />
      </AlertBoxWrapper>
    ) : null
  }

  const onImportClick = async () => {
    const { idOrg, idApp } = sharedProps
    const { idUsersImportParsing } = sharedState

    Analytics.onClickImportUsersAnalytics()
    dispatch(syncUsers({ idOrg, idApp, idUsersImportParsing }))
    navigateToNextStep()
  }

  const onBackClick = () => {
    Analytics.onBackActionAnalytics(stepNameForAnalytics)

    if (customParser) {
      navigateToBackStep(3)
    } else {
      navigateToBackStep(2)
    }
  }

  const onCancelClick = () => {
    Analytics.onCloseImportPopupAnalytics(stepNameForAnalytics, 'Cancel')
    onCancel()
  }

  return (
    <>
      <ToriiPopup.Header header='Review and complete your import' />
      <ToriiPopup.Content>
        {customParserAlertBox()}
        {renderInvalidUsersExpandable()}
        {renderMissingUsersExpandable()}
        {renderValidUsersTable()}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonAction={onCancelClick}
        cancelButtonText='Cancel'
        mainButtonText='Import'
        mainButtonAction={onImportClick}
        isMainButtonDisabled={!validUsers.length}
        showBackButton
        backButtonAction={onBackClick}
      />
    </>
  )
}

export default ReviewImportChanges
