import React, { useState, useRef, ReactElement } from 'react'
import { AppIcon, ButtonType, Spacer, Tooltip, ExpandableSection } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import ToriiSelect from '@components/select'
import flatMap from 'lodash/flatMap'
import { Wrapper, CSS, ROW_HEIGHT, SelectWrapper, GlobalActionWrapper } from './style'
import { ActionWithIdNode } from '@pages/workflow_v2/types'
import { Workflow } from '@shared/workflows/types'
import Confirmation from '@components/confirmation'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'
import {
  ActionFieldOption,
  AppWithActionOptions,
  SelectActionConfirmationData
} from './types'
import { OnActionSelectionChangeData } from '../type'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { SelectActionButton } from './selectActionButton'
import { useSelector } from 'react-redux'
import { getActionsOptionsByApp, getGlobalActionsOptions } from '@selectors/workflows'
import { closedSelectActionConfirmationData } from './consts'
import {
  getSelectActionConfirmationData,
  shouldDisableActionSelection,
  sortByIsToriiActionThenByName,
  sortByName
} from './utils'
import { useTriggerConfiguration } from '@pages/workflow_v2/hooks/useTriggerConfiguration'
import { analytics } from '@shared/services/workflows/analytics'

interface Props {
  workflow: Workflow
  onChange: (option: OnActionSelectionChangeData) => Promise<void>
  onNext: () => void
  action: ActionWithIdNode
}

export const ActionTypeSelection = ({
  workflow,
  onChange,
  onNext,
  action
}: Props): ReactElement => {
  const appActionsList: AppWithActionOptions[] = useSelector(getActionsOptionsByApp)
  const globalActionsList: ActionFieldOption[] = useSelector(getGlobalActionsOptions)

  const sortedAppActions = appActionsList.sort(sortByIsToriiActionThenByName)
  const sortedGlobalActions = globalActionsList.sort(sortByName)

  const [selectedIdApp, setSelectedIdApp] = useState<number | null>(null)
  const [confirmationData, setConfirmationData] = useState<SelectActionConfirmationData>(
    closedSelectActionConfirmationData
  )
  const selectedOption = useRef<ActionFieldOption>()
  const { setIdNewNode } = useWorkflowEditorContext()

  const { isActionTypeSelected } = useActionsConfiguration({ workflow })
  const isActionTypeAlreadySelected = isActionTypeSelected(action.id)

  const { isTriggerSupportBranching } = useTriggerConfiguration({ workflow })

  const onSelectOption = async (option: ActionFieldOption) => {
    if (selectedOption.current === option) {
      return
    }

    selectedOption.current = option
    const { nodes: actionNodes } = workflow.actions
    const currentActionNode = actionNodes[action.id]

    const confirmationData = getSelectActionConfirmationData({
      selectedOption: option,
      isActionTypeAlreadySelected,
      currentActionNode
    })

    analytics.action.select({ actionName: selectedOption.current.label })

    if (confirmationData.isOpen) {
      setConfirmationData(confirmationData)
    } else {
      onActionTypeChange()
    }
  }

  const onSelectGroup = (isOpen: boolean, idApp: number) => {
    setSelectedIdApp(isOpen ? idApp : null)
  }

  const renderOption = (props) => {
    const option = props.data
    const { value, label, description, src } = option

    return (
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <SelectActionButton
          label={label}
          description={description}
          src={src}
          onChange={() => onSelectOption(option)}
          disabled={shouldDisableActionSelection(value, isTriggerSupportBranching)}
        />
      </EnableFor>
    )
  }

  const renderAppIcon = (option: { label: string, appName?: string, imageUrl?: string }) => {
    const { label, appName, imageUrl } = option

    return (
      <Spacer left={'space-300'}>
        <Wrapper height={ROW_HEIGHT}>
          <AppIcon appImageUrl={imageUrl} appName={appName} />
          <Spacer left={'space-200'}>{label}</Spacer>
        </Wrapper>
      </Spacer>
    )
  }

  const renderGroup = (field) => {
    const { idApp, name, options, imageUrl } = field

    const maxHeight = (options.length + 1) * ROW_HEIGHT
    const isOpen = (selectedIdApp === idApp)

    return (
      <ExpandableSection
        key={idApp}
        isOpen={isOpen}
        maxHeight={maxHeight}
        onToggle={(isOpen: boolean) => onSelectGroup(isOpen, idApp)}
        avatar={<AppIcon appImageUrl={imageUrl} appName={name} />}
        title={name}
      >
        {options.map(option => <div key={option.value} {...CSS.expandableRowWrapper}>{renderOption({ data: option })}</div>)}
      </ExpandableSection>
    )
  }

  const getOptionsForSearch = () => {
    const appActions = flatMap(sortedAppActions, field => {
      return field.options.map(option => ({ ...option, src: field.imageUrl }))
    })
    const globalActions = sortedGlobalActions.map(globalAction => ({ ...globalAction, src: globalAction.imageUrl }))

    return [...globalActions, ...appActions]
  }

  const cancelConfirmation = () => {
    closeConfirmation()
    analytics.action.replace.popupClosed({ buttonLabel: 'Cancel' })
  }

  const exitConfirmation = () => {
    closeConfirmation()
    analytics.action.replace.popupClosed({ buttonLabel: 'X' })
  }

  const closeConfirmation = () => setConfirmationData(closedSelectActionConfirmationData)

  const onActionTypeChange = async (replaceAction = false) => {
    if (selectedOption.current) {
      setIdNewNode(action.id)
      await onChange(selectedOption.current)
      replaceAction && analytics.action.replace.replaced()
      onNext()
    }

    closeConfirmation()
  }

  const renderGlobalActions = () => {
    return sortedGlobalActions.map((globalAction) => {
      const disabled = shouldDisableActionSelection(globalAction.type, isTriggerSupportBranching)

      return (
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]} key={globalAction.type} disabled={disabled}>
          <GlobalActionWrapper onClick={() => onSelectOption(globalAction)}>
            <Tooltip label={'This action is not yet available for workflows with the chosen trigger.'} hide={!disabled} placement='top'>
              {renderAppIcon({ label: globalAction.label, imageUrl: globalAction.imageUrl })}
            </Tooltip>
          </GlobalActionWrapper>
        </EnableFor>
      )
    })
  }

  const options = getOptionsForSearch()

  return (<>
    <div>
      <SelectWrapper>
        <ToriiSelect
          icon='Search'
          options={options}
          optionRenderer={renderOption}
          placeholder='Search actions'
          clearable={false}
          tabSelectsValue={false}
          onChange={newValue => onSelectOption(newValue as ActionFieldOption)}
        />
      </SelectWrapper>
      {renderGlobalActions()}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>{sortedAppActions.map(renderGroup)}</div>
    </div>
    <Confirmation
      {...confirmationData}
      confirm={() => onActionTypeChange(true)}
      close={exitConfirmation}
      declineText='Cancel'
      decline={cancelConfirmation}
      mainButtonType={ButtonType.primary}
    />
  </>)
}
