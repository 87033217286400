import React, { Fragment, useCallback, useEffect, useState } from 'react'
import ToriiPopup from '../../../ToriiPopupV2'
import { ChangesPreviewResponse, SharedProps, SharedState } from '../types'
import { PopupRendererProps } from '../../../wizardPopup/types'
import { Styles } from '../styles'
import { useSelector, useDispatch } from 'react-redux'
import { getIdOrg, getLifecycleApps } from '@selectors/org'
import SelectAppGroupDivided from '@root/components/selectAppGroupDivided'
import { App, AppGroup } from '@root/components/selectAppGroupDivided/types'
import groupBy from 'lodash/groupBy'
import { getUserTypeSourcePreview } from '@root/shared/actions/org'
import Spinner from '@media/spinner.svg'
import { SourceFilter, UserTypeSource } from '@root/components/userTypeSources/types'
import { INTEGRATION_CATEGORY, SCOPES, TABLES } from '@root/constants'
import AdvancedFilters from '@components/advancedFilters'
import { getAppUsersFieldValues, getUserDetailsFields } from '@shared/actions'
import { getUserTablesInfo } from '@selectors/tables'
import { getValue, useParamSelector } from '@shared/utils'
import { opsWithValuesDropdown } from '@lenses/filters'
import { getAppFieldValues } from '@selectors/appUsers'
import { ActionForUserTypePreview } from '@shared/actions/org.types'
import { isFieldSupportAutocompleteValues } from '@shared/fieldUtils'

const FILTER_VALUES_TO_SHOW = new Set(['originFullName', 'originEmail', 'appAccountName', 'licenses.name'])

export const appCategoriesByUserType: Record<SharedProps['userType'], string[]> = {
  employee: [INTEGRATION_CATEGORY.HUMAN_RESOURCE, INTEGRATION_CATEGORY.IDENTITY_PROVIDER]
}

export const EditUserTypeSources = ({
  onCancel, navigateToNextStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)

  const apps: App[] = useSelector(getLifecycleApps)
  const appFromProps = apps.find(app => app.idApp === sharedProps.sourceToEdit?.idApp)
  const filterFromProps = sharedProps.sourceToEdit?.filter
  const appsByCategory = groupBy(apps, 'integrationCategory' as keyof App)

  const [selectedApp, setSelectedApp] = useState<App | undefined>(sharedState.selectedApp || appFromProps)
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false)
  const [filters, setFilters] = useState<SourceFilter[]>(filterFromProps || [])
  const [prevChangesPreview, setPrevChangesPreview] = useState<ChangesPreviewResponse | undefined>(undefined)
  const [showPreviewUsersCount, setShowPreviewUsersCount] = useState<boolean>(false)

  const idApp = selectedApp?.idApp

  const { filtersOptions = [], originFiltersOptions = [] } = useSelector(getUserTablesInfo)[TABLES.appUsersTable.key]
  const optionsKey = originFiltersOptions
    .concat(filtersOptions)
    .filter(option => FILTER_VALUES_TO_SHOW.has(option.value) || option.idApp === idApp)
  const optionsValuesPerKey = useParamSelector(getAppFieldValues, { idApp })

  useEffect(() => {
    if (idOrg) {
      dispatch(getUserDetailsFields({ idOrg }))
    }
  }, [dispatch, idOrg])

  const fetchFieldsValues = useCallback(filters => {
    const isOpValidAndNotFetchedYet = filter => opsWithValuesDropdown.includes(getValue(filter.op)) && !optionsValuesPerKey[getValue(filter.key)]
    const filtersToFetch = filters.filter(isOpValidAndNotFetchedYet)

    const fields = filtersToFetch
      .map(filterToFetch => getValue(filterToFetch.key))
      .filter(field => {
        const isFieldSupportValues = isFieldSupportAutocompleteValues({
          fieldOptions: optionsKey,
          fieldOption: field
        })
        return isFieldSupportValues && idApp
      })

    if (fields?.length && idApp && idOrg) {
      dispatch(getAppUsersFieldValues({ idOrg, idApp, fields, status: undefined }))
    }
  }, [dispatch, idApp, idOrg, optionsKey, optionsValuesPerKey])

  useEffect(() => {
    if (selectedApp && filters) {
      fetchFieldsValues(filters)
    }
  }, [fetchFieldsValues, filters, selectedApp])

  const onSetFilters = filters => {
    setFilters(filters)
    fetchFieldsValues(filters)
  }

  const onFilterChange = async filters => {
    onSetFilters(filters)
    if (selectedApp) {
      await onAppSelected(selectedApp, filters)
    }
  }

  const { userType } = sharedProps
  const appCategoriesToSelectFrom = appCategoriesByUserType[userType]
  const appGroups: AppGroup[] = appCategoriesToSelectFrom.reduce((groups: AppGroup[], category) => {
    if (!appsByCategory[category]?.length) {
      return groups
    }
    groups.push({
      groupName: appsByCategory[category][0].category,
      apps: appsByCategory[category]
    })
    return groups
  }, [])

  async function onAppSelected (app: App, filters: SourceFilter[]) {
    let stateFilter = filters

    setSelectedApp(prevApp => {
      if (filters.length && prevApp?.idApp !== app?.idApp) {
        stateFilter = []
      }

      onSetFilters(stateFilter)
      return app
    })

    setIsLoadingPreview(true)

    const userTypeSource: UserTypeSource = {
      id: sharedProps.sourceToEdit?.id,
      idOrg,
      idApp: app.idApp,
      filter: stateFilter,
      type: sharedProps.userType
    }

    const action = sharedProps.sourceToEdit ? ActionForUserTypePreview.UPDATE : ActionForUserTypePreview.CREATE
    const changesPreview = await dispatch(getUserTypeSourcePreview({ userTypeSource, action })) as ChangesPreviewResponse

    if (changesPreview.isSuccess) {
      setPrevChangesPreview({ ...changesPreview, isSuccess: false })
    }

    setState({ userTypeSource, action, selectedApp: app, changesPreview: changesPreview.isSuccess ? changesPreview : prevChangesPreview })
    setIsLoadingPreview(false)

    setShowPreviewUsersCount(true)
  }

  const { changesPreview } = sharedState

  return (<Fragment>
    <ToriiPopup.Header
      header={sharedProps.formHeader}
      subHeader={sharedProps.formSubHeader}
    />
    <ToriiPopup.Content>
      <Styles.Form>
        <Styles.Select>
          <Styles.SelectLabel>Select source of truth</Styles.SelectLabel>
          <SelectAppGroupDivided
            appGroups={appGroups}
            selectedApp={selectedApp}
            onAppSelected={app => onAppSelected(app, filters)}
          />
        </Styles.Select>
        <AdvancedFilters
          optionsKey={optionsKey}
          optionsValuesPerKey={optionsValuesPerKey}
          filters={filters}
          onChange={filters => onFilterChange(filters)}
          disabled={!selectedApp?.idApp}
        />
        {isLoadingPreview ? (
          <Styles.LoadingLabel>
            <Styles.LoadingLabelImage alt='Loading' src={Spinner} />Loading
          </Styles.LoadingLabel>
        ) : showPreviewUsersCount && (
          <Styles.UsersCountLabel>
            {`${changesPreview?.results?.sourceMatchedUsersFoundCount || 0} ${sharedProps.usersLabel} and ${changesPreview?.results?.sourceMatchedPastUsersFoundCount || 0} past ${sharedProps.usersLabel} found`}
          </Styles.UsersCountLabel>
        )}
      </Styles.Form>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Preview changes'}
      isMainButtonDisabled={!selectedApp || !changesPreview?.isSuccess}
      mainButtonAction={() => navigateToNextStep()}
      mainButtonVisibilityScopes={[SCOPES.SETTINGS_WRITE]}
      showBackButton
      backButtonText={'Back'}
      isBackButtonDisabled />
  </Fragment>)
}
