import {
  getTriggersByType,
  getWorkflowExecutions,
  getWorkflowExecutionsState,
  getWorkflowActionExecutions,
  getActionsConfigByType,
  getTriggersPersonalizationConfig,
  getActionsPersonalizationConfig,
  getSelectOptionsOfCreateTicketActions,
  getDynamicPersonalizationConfig
} from '@selectors/workflows'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import reduce from 'lodash/reduce'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT, formFieldTypes,
  NO_APP_ACCOUNT_ID,
  OFFBOARDING_METHOD_TYPE,
  TORII_APP_ID,
  WORKFLOW_ACTION_TYPES,
  WORKFLOW_OUTPUT_FIELD_TYPES,
  WORKFLOW_TRIGGER_TYPES,
  WORKFLOW_TYPES
} from '../constants'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'
import colors from '@shared/style/colors'
import pluralize from 'pluralize'
import isNil from 'lodash/isNil'
import { getAppCatalogPolicies } from '@shared/actions'
import uniqBy from 'lodash/uniqBy'
import partition from 'lodash/partition'
import keyBy from 'lodash/keyBy'
import { getDisplayName } from '@lenses/users'

const getFieldPlaceholders = (idAction, actionIndex, placeholders) => {
  return reduce(placeholders, (placeholdersWithActionInfo, placeholderValue, placeholderKey) => {
    placeholdersWithActionInfo[placeholderKey.replace('{action.id}', `action_${idAction}`)] = `Action ${actionIndex}: ${placeholderValue}`
    return placeholdersWithActionInfo
  }, {})
}

export const getActionsPlaceholders = (actions, actionsByType) => {
  return actions.reduce((prevActionsPlaceholders, action, index) => {
    const actionType = action.type
    const idAction = action.id
    const actionsOutputPlaceholders = get(actionsByType, [actionType, 'outputSchemaPlaceholders'], {})
    const actionPlaceholders = getFieldPlaceholders(idAction, index + 1, actionsOutputPlaceholders)
    return { ...prevActionsPlaceholders, ...actionPlaceholders }
  }, {})
}

export const getPersonalizationByTriggerAndAction = (state, triggerType, actionType, prevActions = []) => {
  const triggersPlaceholdersByTriggerType = getTriggersPersonalizationConfig(state)
  const actionsPlaceholdersByActionType = getActionsPersonalizationConfig(state)

  const triggerPlaceholders = get(triggersPlaceholdersByTriggerType, [triggerType], {})
  const actionPlaceholders = get(actionsPlaceholdersByActionType, [actionType, 'uiConfigPlaceholders'], {})
  const prevActionsPlaceholders = getActionsPlaceholders(prevActions, actionsPlaceholdersByActionType)

  const placeholders = { ...triggerPlaceholders, ...actionPlaceholders, ...prevActionsPlaceholders }
  return Object.keys(placeholders).map(key => ({ id: key, display: placeholders[key] }))
}

const getFieldPlaceholdersV2 = (idAction, actionLabel, placeholders) => {
  return reduce(placeholders, (placeholdersWithActionInfo, placeholderValue, placeholderKey) => {
    placeholdersWithActionInfo[placeholderKey.replace('{action.id}', `action_${idAction}`)] = `Action ${actionLabel}: ${placeholderValue}`
    return placeholdersWithActionInfo
  }, {})
}

export const getActionsPlaceholdersV2 = (actions, actionsByType, idWorkflow, state) => {
  const actionsConfigByType = getActionsConfigByType(state)
  const dynamicPersonalizationConfig = getDynamicPersonalizationConfig(state)
  const actionsDynamicPlaceholdersByActionId = dynamicPersonalizationConfig?.[idWorkflow]?.actions || {}
  return actions.reduce((prevActionsPlaceholders, action) => {
    const actionType = action.type
    const idAction = action.id
    const actionConfig = actionsConfigByType[actionType] || {}
    const actionLabel = get(actionConfig, ['uiConfig', 'label'])
    const actionDynamicPlaceholders = get(actionsDynamicPlaceholdersByActionId, idAction, {})
    const actionsOutputPlaceholders = get(actionsByType, [actionType, 'outputSchemaPlaceholders'], {})
    const actionPlaceholders = getFieldPlaceholdersV2(idAction, actionLabel, actionsOutputPlaceholders)
    return { ...prevActionsPlaceholders, ...actionPlaceholders, ...actionDynamicPlaceholders }
  }, {})
}

export const getPersonalizationByTriggerAndActionV2 = (state, triggerType, actionType, prevActions = [], idWorkflow) => {
  const triggersPlaceholdersByTriggerType = getTriggersPersonalizationConfig(state)
  const actionsPlaceholdersByActionType = getActionsPersonalizationConfig(state)

  const triggerPlaceholders = get(triggersPlaceholdersByTriggerType, [triggerType], {})
  const dynamicPersonalizationConfig = getDynamicPersonalizationConfig(state)
  const triggerDynamicPlaceholders = dynamicPersonalizationConfig?.[idWorkflow]?.trigger || {}
  const actionPlaceholders = get(actionsPlaceholdersByActionType, [actionType, 'uiConfigPlaceholders'], {})
  const prevActionsPlaceholders = getActionsPlaceholdersV2(prevActions, actionsPlaceholdersByActionType, idWorkflow, state)

  const placeholders = {
    ...triggerPlaceholders,
    ...triggerDynamicPlaceholders,
    ...actionPlaceholders,
    ...prevActionsPlaceholders
  }
  return Object.keys(placeholders).map(key => ({ id: key, display: placeholders[key] }))
}

export const getWorkflowExecutionsById = (state, idWorkflow) => {
  const executions = getWorkflowExecutions(state)
  return executions[idWorkflow] || []
}

export const getWorkflowExecutionsStateById = (state, idWorkflow) => {
  const executionsState = getWorkflowExecutionsState(state)
  return executionsState[idWorkflow] || {}
}

export const getWorkflowActionExecutionsById = (state, idWorkflowExecution) => {
  const actions = getWorkflowActionExecutions(state)
  return actions[idWorkflowExecution] || []
}

export const getWorkflowFieldValueByActionStepAndFieldId = (actions = [], { actionType, fieldId }) => {
  const fields = (actions.find(action => action.type === actionType) || {}).fields
  if (!fields) {
    return null
  }
  return (fields.find(field => field.id === fieldId) || {}).value
}

export const getUsersSpecialOptionsByTrigger = (state, triggerType, field) => {
  const triggerTypes = getTriggersByType(state)
  if (!triggerType || isEmpty(triggerTypes)) {
    return null
  }

  const userConfig = get(triggerTypes[triggerType], 'outputSchema')
  const userConfigKeys = Object.keys(userConfig)
  const specialUsersFromOutputSchema = userConfigKeys.map(key => {
    const config = userConfig[key]

    if (config.type !== WORKFLOW_OUTPUT_FIELD_TYPES.USER) {
      return null
    }

    return {
      value: config.id,
      label: config.name,
      description: config.description,
      optional: config.optional
    }
  })

  return compact([...specialUsersFromOutputSchema, ...((field || {}).specialUsers || [])])
}

export const filterUsersCustomOptionsByTrigger = (state, userOptions, triggerType) => {
  const triggerTypes = getTriggersByType(state)
  if (!triggerType || isEmpty(triggerTypes)) {
    return userOptions
  }

  const outputSchemaConfig = Object.values(get(triggerTypes[triggerType], 'outputSchema', {}))
  const hasUserField = outputSchemaConfig.some(config => config.type === WORKFLOW_OUTPUT_FIELD_TYPES.USER) && outputSchemaConfig.find(config => config.id === 'triggerUser')
  const hasAppField = outputSchemaConfig.some(config => config.type === WORKFLOW_OUTPUT_FIELD_TYPES.APP)
  const hasContractField = outputSchemaConfig.some(config => config.type === WORKFLOW_OUTPUT_FIELD_TYPES.CONTRACT)

  let filteredUserOptions = [].concat(userOptions)

  if (!hasUserField) {
    filteredUserOptions = filteredUserOptions.filter(option => option.type !== 'userField')
  }
  if (!hasAppField) {
    filteredUserOptions = filteredUserOptions.filter(option => option.type !== 'appDetail')
  }
  if (!hasContractField) {
    filteredUserOptions = filteredUserOptions.filter(option => option.type !== 'contractDetail')
  }

  return filteredUserOptions
}

export const getUsersSpecialOptionsByTriggerType = (triggerByType, triggerType) => {
  if (isEmpty(triggerByType)) {
    return null
  }

  const userConfig = get(triggerByType[triggerType], 'outputSchema')
  const userConfigKeys = Object.keys(userConfig)
  const specialUsersFromOutputSchema = userConfigKeys.map(key => {
    const config = userConfig[key]

    if (config.type !== WORKFLOW_OUTPUT_FIELD_TYPES.USER) {
      return null
    }

    return {
      value: config.id,
      label: config.name,
      description: config.description,
      optional: config.optional
    }
  })

  return compact(specialUsersFromOutputSchema)
}

export const createAction = (triggerType, actionType, stateProps) => {
  if (!actionType) {
    return {}
  }

  const { actionsConfig, fieldsDefaultValues, actionsByType } = stateProps
  const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === actionType)
  const { fields } = getStepsAndFieldsWithDefaultValues(triggerType, actionConfig, fieldsDefaultValues, actionsByType)

  return { id: new Date().getTime(), type: actionType, idApp: actionConfig.idApp, fields }
}

export const getTriggerPreviewPropsByTriggerType = (triggerType) => {
  const defaultPopupWidth = '600px'
  const PreviewTableTheadTh = {
    style: {
      height: 0,
      padding: 0
    }
  }
  const injectTheadThSortOrder = (table, _, column) => {
    const sortOrder = table.sorted.find(sorted => sorted.id === column.id)
    return {
      style: {
        textAlign: 'left',
        display: 'flex',
        border: 0,
        padding: '16px 20px',
        color: sortOrder && colors.darkText,
        boxShadow: 'none',
        ...column.style
      },
      sortOrder: sortOrder && (sortOrder.desc ? 'desc' : 'asc')
    }
  }

  const propsByTriggerType = {
    [WORKFLOW_TRIGGER_TYPES.USER_MEETS_CRITERIA]: {
      getTheadThProps: () => PreviewTableTheadTh,
      popupWidth: defaultPopupWidth,
      getPreviewCountText: ({ previewEntity, previewEntitiesAmount }) => `${pluralize(previewEntity, previewEntitiesAmount, true)} meet the trigger criteria now`
    },
    [WORKFLOW_TRIGGER_TYPES.CONTRACT_MEETS_CRITERIA]: {
      getTheadThProps: () => PreviewTableTheadTh,
      popupWidth: defaultPopupWidth,
      getPreviewCountText: ({ previewEntity, previewEntitiesAmount }) => `${pluralize(previewEntity, previewEntitiesAmount, true)} meet the trigger criteria now`
    },
    [WORKFLOW_TRIGGER_TYPES.APPLICATION_MEETS_CRITERIA]: {
      getTheadThProps: () => PreviewTableTheadTh,
      popupWidth: defaultPopupWidth,
      getPreviewCountText: ({ previewEntity, previewEntitiesAmount }) => `${pluralize(previewEntity, previewEntitiesAmount, true)} meet the trigger criteria now`
    },
    [WORKFLOW_TRIGGER_TYPES.USER_STOP_USING_LICENSE]: {
      getTheadThProps: injectTheadThSortOrder,
      popupWidth: '900px',
      getPreviewCountText: ({ previewEntity, previewEntitiesAmount }) => `${pluralize(previewEntity, previewEntitiesAmount, true)} ${pluralize('is', previewEntitiesAmount)} not in use now`
    },
    default: {
      getTheadThProps: () => PreviewTableTheadTh,
      popupWidth: defaultPopupWidth,
      getPreviewCountText: ({ previewEntity, previewEntitiesAmount }) => `${pluralize(previewEntity, previewEntitiesAmount, true)} meet the trigger criteria now`
    }
  }
  return propsByTriggerType[triggerType] || propsByTriggerType.default
}

const isAppCatalogPolicy = ({ workflow }) => {
  return get(workflow, ['type']) === WORKFLOW_TYPES.appCatalog
}

export const isCustomAppCatalogPolicy = ({ workflow }) => {
  const triggerIdApp = get(workflow, ['triggerIdApp'])
  return isAppCatalogPolicy({ workflow }) && !isNil(triggerIdApp)
}

export const isDefaultAppCatalogPolicy = ({ workflow }) => {
  const triggerIdApp = get(workflow, ['triggerIdApp'])
  return isAppCatalogPolicy({ workflow }) && isNil(triggerIdApp)
}

export const getWorkflowPathPrefix = ({ workflowType, triggerType } = { workflowType: WORKFLOW_TYPES.regular }) => {
  const MAP_WORKFLOW_TYPE_TO_PATH_PREFIX = {
    [WORKFLOW_TYPES.regular]: 'workflow',
    [WORKFLOW_TYPES.appCatalog]: triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP ? 'appCatalog/newAppPolicy' : 'appCatalog/accessPolicy'
  }

  return MAP_WORKFLOW_TYPE_TO_PATH_PREFIX[workflowType] || MAP_WORKFLOW_TYPE_TO_PATH_PREFIX[WORKFLOW_TYPES.regular]
}

export const getWorkflowsPathPrefix = ({ workflowType, triggerType } = { workflowType: WORKFLOW_TYPES.regular }) => {
  const MAP_WORKFLOW_TYPE_TO_PATH_PREFIX = {
    [WORKFLOW_TYPES.regular]: 'workflows',
    [WORKFLOW_TYPES.appCatalog]: triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP ? 'appCatalog/newAppPolicies' : 'appCatalog/accessPolicies'
  }

  return MAP_WORKFLOW_TYPE_TO_PATH_PREFIX[workflowType] || MAP_WORKFLOW_TYPE_TO_PATH_PREFIX[WORKFLOW_TYPES.regular]
}

export const getPolicyDeleteLogicProps = ({ idOrg, dispatch, triggerType = null }) => {
  return {
    getUpdatedWorkflowsList: async () => dispatch(getAppCatalogPolicies({ idOrg })),
    getConfirmationHeader: (workflowName) => `Delete '${workflowName}'?`,
    confirmationText: triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP ? 'By clicking "Delete", all data will be lost.' : `By clicking "Delete", all data will be lost and the app will be assigned the default request access policy.`
  }
}

export const getNewPolicyName = (appName) => `${appName} access request policy`

export const getInvalidWorkflowTooltipText = ({ type, triggerType, isValid, isActive, isCustomPolicy, defaultTriggerIdApps = [] }) => {
  if (isValid && isActive) {
    return ''
  }

  let tooltipText = isActive ? 'Workflow is disabled due to invalid configuration.' : 'Invalid workflow configuration'

  if (type === WORKFLOW_TYPES.appCatalog) {
    if (triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP) {
      tooltipText = 'This policy has an invalid configuration and will not run.'
    } else {
      const suffixPolicyTooltipText = isCustomPolicy ? ` For app requests, the default policy will be applied.` : ` Nothing will happen when employees request access to any of the ${defaultTriggerIdApps.length} apps.`
      tooltipText = `Policy is not active.${suffixPolicyTooltipText}`
    }
  }

  return tooltipText
}

export const getSingleUserCustomUserOptions = (customUserOptions, appDetailsFields) => {
  const appDetailsSingleUserSystemKey = keyBy(appDetailsFields.filter(field => field.type === formFieldTypes.usersDropdown), 'systemKey')
  return compact(customUserOptions.map(userOption => {
    if (userOption.type !== 'appDetail') {
      return userOption
    }
    if (userOption.type === 'appDetail' && appDetailsSingleUserSystemKey[userOption.value]) {
      return userOption
    }
    return null
  }))
}

const actionsToOmitFromAutomaticActionList = {
  170: [WORKFLOW_ACTION_TYPES.SLACK_NOTIFICATION],
  102: [WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE]
}

const automaticToriiGlobalActions = {
  sendHttpRequest: true
}

const automaticToriiAppActions = {
  sendHttpRequest: true,
  removeToriiMember: true
}

export const delegateOffboardingActions = [
  WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK,
  WORKFLOW_ACTION_TYPES.JIRA_CLOUD_CREATE_ISSUE
]

export const getCreateTicketActionsByOffboardingType = (state) => {
  const createTicketActions = getSelectOptionsOfCreateTicketActions(state) || []
  const [delegateCreateTicketActions, automaticCreateTicketActions] = partition(createTicketActions, (action) => delegateOffboardingActions.includes(action.value))
  return {
    [OFFBOARDING_METHOD_TYPE.AUTOMATIC]: automaticCreateTicketActions,
    [OFFBOARDING_METHOD_TYPE.DELEGATE]: delegateCreateTicketActions
  }
}

export const getAutomaticActionList = ({ idApp, idAppAccount, actionsByApp, createTicketActions } = {}) => {
  const sortedCreateTicketActions = createTicketActions.sort((a, b) => (a.label.localeCompare(b.label, 'en')))

  const appActions = idAppAccount !== NO_APP_ACCOUNT_ID ? actionsByApp : EMPTY_OBJECT
  const toriiActions = actionsByApp[TORII_APP_ID] || EMPTY_ARRAY
  const automaticActions = (appActions[idApp] || [])
  const appActionToOmit = actionsToOmitFromAutomaticActionList[idApp] || EMPTY_ARRAY

  const isToriiActionsToShow = (action) => idApp === TORII_APP_ID ? automaticToriiAppActions[action.value] : automaticToriiGlobalActions[action.value]
  const isAppActionToShow = (action) => !appActionToOmit.includes(action.value)

  const toriiActionsToShow = toriiActions.filter(action => isToriiActionsToShow(action))
  const automaticActionsToShow = idApp === TORII_APP_ID ? EMPTY_ARRAY : automaticActions.filter(action => isAppActionToShow(action))

  const actionSelectOptions = automaticActionsToShow
    .concat(sortedCreateTicketActions)
    .concat(toriiActionsToShow)

  return uniqBy(actionSelectOptions, 'value')
}

export const getDelegateActionsList = ({ actionsByApp, createTicketActions } = {}) => {
  const createTicketDelegateActions = createTicketActions.filter((action) => delegateOffboardingActions.includes(action.value))
  const toriiDelegateActions = (actionsByApp[TORII_APP_ID] || []).filter(action => delegateOffboardingActions.includes(action.value))

  const actionSelectOptions = toriiDelegateActions.concat(createTicketDelegateActions)

  return actionSelectOptions
}

export const getFinalActionLabel = (actionConfig, action) => {
  const uiConfig = get(actionConfig, 'uiConfig')
  const label = get(uiConfig, 'label')

  let finalLabel = label
  const dynamicLabel = get(uiConfig, 'dynamicLabel')

  if (dynamicLabel) {
    const { valueFromFieldId: fieldId, stringToReplace } = dynamicLabel
    const { value: fieldValue = '' } = action.fields.find(field => field.id === fieldId) ?? {}
    if (fieldValue.trim() !== '') {
      finalLabel = label.replace(stringToReplace, fieldValue)
    }
  }
  return finalLabel
}

export const getFinalActionLabelV2 = (actionConfig, action) => {
  const isActionSelected = Boolean(action.type)
  if (!isActionSelected) {
    return 'New action'
  }

  return getFinalActionLabel(actionConfig, action)
}

export const getRecipientName = (recipient) => {
  const recipientName = getDisplayName(recipient)

  if (recipient.emailUserField) {
    return `${recipientName} - ${recipient.emailUserField.name}`
  }

  return recipientName
}
