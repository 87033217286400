import {
  GET_WORKFLOWS_TEMPLATES,
  DELETE_WORKFLOW,
  GET_WORKFLOWS_TRIGGERS_CONFIG,
  GET_WORKFLOWS_ACTIONS_CONFIG,
  CREATE_WORKFLOW,
  GET_WORKFLOW_EXECUTIONS,
  GET_WORKFLOW_ACTION_EXECUTIONS,
  GET_WORKFLOWS_ACTIONS_FIELDS,
  GET_WORKFLOWS_TRIGGERS_FIELDS,
  GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES,
  GET_WORKFLOW_TRIGGER_PREVIEW,
  WORKFLOW_TYPES,
  GET_WORKFLOWS_PERSONALIZATION_CONFIG,
  GET_WORKFLOWS_DYNAMIC_PERSONALIZATION_CONFIG,
  GET_APP_CATALOG_POLICIES,
  GET_WORKFLOW_AUDIT_LOGS,
  UPDATE_WORKFLOW,
  GET_WORKFLOW,
  DUPLICATE_WORKFLOW,
  GET_WORKFLOW_AUDIT_LOG_INFO,
  GET_WORKFLOWS,
  CLEAR_WORKFLOW_ACTION_FIELDS,
  GET_NOTIFY_ON_ERRORS_WORKFLOWS,
  GET_WORKFLOW_TAGS,
  UPDATE_WORKFLOW_TAGS,
  GET_WORKFLOWS_EXECUTION_AUDIT,
  DELETE_WORKFLOW_TAG,
  UPDATE_WORKFLOW_TAG,
  CREATE_WORKFLOW_TAGS,
  GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES,
  WORKFLOW_TRIGGER_TYPES,
  GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES
} from '../constants'
import omit from 'lodash/omit'
import { uniq } from 'lodash'

const initialState = {
  loading: false,
  workflows: [],
  appCatalogPolicies: {
    policies: [],
    resources: { apps: {}, users: {} },
    loading: false
  },
  appCatalogRequestNewAppPolicies: {
    policies: [],
    resources: { apps: {}, users: {} },
    loading: false
  },
  notifyOnErrorsWorkflows: [],
  resources: { users: {} },
  updating: false,
  version: null,
  workflowsTemplates: [],
  config: {},
  executions: {},
  executionsState: {},
  workflowActionExecutions: {
    actions: {},
    loading: false
  },
  actionDynamicFieldsOptions: {
    loading: false
  },
  triggerDynamicFieldsOptionsValues: {},
  triggerPreview: {},
  workflowsAuditLogs: {
    loading: false,
    auditLogs: {},
    extraInfoByIdAudit: {}
  },
  hasUnsavedChanges: false,
  workflowTags: {
    tags: [],
    loading: false
  },
  fullAuditLogs: {
    loading: false,
    loadingMore: false,
    count: null,
    audits: [],
    resources: { apps: {}, users: {} }
  },
  unavailableWorkflowIds: []
}

const workflowsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_WORKFLOWS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_WORKFLOWS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_WORKFLOWS}_RESPONSE`: {
      const { workflows, resources } = action.payload
      return {
        ...state,
        loading: false,
        workflows,
        resources: { users: resources.users }
      }
    }
    case `${GET_APP_CATALOG_POLICIES}_PENDING`: {
      return {
        ...state,
        appCatalogPolicies: {
          ...state.appCatalogPolicies,
          loading: true
        }
      }
    }
    case `${GET_APP_CATALOG_POLICIES}_FAILED`: {
      return {
        ...state,
        appCatalogPolicies: {
          ...state.appCatalogPolicies,
          loading: false
        }
      }
    }
    case `${GET_APP_CATALOG_POLICIES}_RESPONSE`: {
      const { workflows, resources } = action.payload
      return {
        ...state,
        appCatalogPolicies: {
          policies: workflows,
          resources,
          loading: false
        }
      }
    }
    case `${GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES}_PENDING`: {
      return {
        ...state,
        appCatalogRequestNewAppPolicies: {
          ...state.appCatalogRequestNewAppPolicies,
          loading: true
        }
      }
    }
    case `${GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES}_FAILED`: {
      return {
        ...state,
        appCatalogRequestNewAppPolicies: {
          ...state.appCatalogRequestNewAppPolicies,
          loading: false
        }
      }
    }
    case `${GET_APP_CATALOG_REQUEST_NEW_APP_POLICIES}_RESPONSE`: {
      const { workflows, resources } = action.payload
      return {
        ...state,
        appCatalogRequestNewAppPolicies: {
          policies: workflows,
          resources,
          loading: false
        }
      }
    }
    case `${GET_NOTIFY_ON_ERRORS_WORKFLOWS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_NOTIFY_ON_ERRORS_WORKFLOWS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_NOTIFY_ON_ERRORS_WORKFLOWS}_RESPONSE`: {
      const { workflows } = action.payload
      return {
        ...state,
        loading: false,
        notifyOnErrorsWorkflows: workflows
      }
    }
    case `${DELETE_WORKFLOW}_PENDING`: {
      const { idWorkflow, type, triggerType } = action.meta

      if (type === WORKFLOW_TYPES.appCatalog) {
        if (triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_ACCESS) {
          const updatedPolicies = state.appCatalogPolicies.policies.filter(policy => policy.id !== idWorkflow)
          return {
            ...state,
            appCatalogPolicies: {
              ...state.appCatalogPolicies,
              policies: updatedPolicies
            }
          }
        } else if (triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP) {
          const updatedPolicies = state.appCatalogRequestNewAppPolicies.policies.filter(policy => policy.id !== idWorkflow)
          return {
            ...state,
            appCatalogRequestNewAppPolicies: {
              ...state.appCatalogRequestNewAppPolicies,
              policies: updatedPolicies
            }
          }
        }
      }

      const updatedWorkflows = state.workflows.filter(raw => raw.id !== idWorkflow)
      return {
        ...state,
        workflows: updatedWorkflows
      }
    }
    case `${GET_WORKFLOWS_TEMPLATES}_RESPONSE`: {
      return {
        ...state,
        workflowsTemplates: action.payload.templates
      }
    }
    case `${CREATE_WORKFLOW}_RESPONSE`: {
      const { workflow, resources } = action.payload
      const { users = [], apps = [] } = resources || {}

      let workflowTypeToStateUpdate = {}

      if (workflow.type === WORKFLOW_TYPES.notifyOnErrors) {
        workflowTypeToStateUpdate = {
          notifyOnErrorsWorkflows: updateWorkflow(state.notifyOnErrorsWorkflows, workflow)
        }
      } else if (workflow.type === WORKFLOW_TYPES.appCatalog) {
        workflowTypeToStateUpdate = {
          appCatalogPolicies: {
            ...state.appCatalogPolicies,
            policies: updateWorkflow(state.appCatalogPolicies.policies, workflow),
            resources: {
              users: { ...state.appCatalogPolicies.users, ...users },
              apps: { ...state.appCatalogPolicies.apps, ...apps }
            }
          }
        }
      } else {
        workflowTypeToStateUpdate = { workflows: updateWorkflow(state.workflows, workflow) }
      }

      return {
        ...state,
        ...workflowTypeToStateUpdate
      }
    }

    case UPDATE_WORKFLOW:
    case `${UPDATE_WORKFLOW}_PENDING`: {
      const { workflow, idWorkflow, locally, version } = action.meta
      const updating = !locally
      let workflowTypeToStateUpdate = {}

      if (workflow.type === WORKFLOW_TYPES.notifyOnErrors) {
        workflowTypeToStateUpdate = {
          notifyOnErrorsWorkflows: mergeWorkflow(state.notifyOnErrorsWorkflows, workflow, workflow.id)
        }
      } else if (workflow.type === WORKFLOW_TYPES.appCatalog) {
        workflowTypeToStateUpdate = {
          appCatalogPolicies: {
            ...state.appCatalogPolicies,
            policies: mergeWorkflow(state.appCatalogPolicies.policies, workflow, idWorkflow)
          }
        }
      } else {
        workflowTypeToStateUpdate = { workflows: mergeWorkflow(state.workflows, workflow, idWorkflow) }
      }

      return {
        ...state,
        ...workflowTypeToStateUpdate,
        updating,
        version: version || state.version,
        hasUnsavedChanges: locally
      }
    }
    case `${UPDATE_WORKFLOW}_RESPONSE`: {
      const { workflow } = action.payload
      const { version } = action.meta

      if (version !== state.version) {
        return state
      }

      let workflowTypeToStateUpdate = {}

      if (workflow.type === WORKFLOW_TYPES.notifyOnErrors) {
        workflowTypeToStateUpdate = {
          notifyOnErrorsWorkflows: updateWorkflow(state.notifyOnErrorsWorkflows, workflow)
        }
      } else if (workflow.type === WORKFLOW_TYPES.appCatalog) {
        if (workflow.triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_ACCESS) {
          workflowTypeToStateUpdate = {
            appCatalogPolicies: {
              ...state.appCatalogPolicies,
              policies: updateWorkflow(state.appCatalogPolicies.policies, workflow)
            }
          }
        } else if (workflow.triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP) {
          workflowTypeToStateUpdate = {
            appCatalogRequestNewAppPolicies: {
              ...state.appCatalogRequestNewAppPolicies,
              policies: updateWorkflow(state.appCatalogRequestNewAppPolicies.policies, workflow)
            }
          }
        }
      } else {
        workflowTypeToStateUpdate = { workflows: updateWorkflow(state.workflows, workflow) }
      }

      return {
        ...state,
        updating: false,
        version: null,
        ...workflowTypeToStateUpdate
      }
    }
    case `${UPDATE_WORKFLOW}_FAILED`: {
      const { version } = action.meta

      if (version !== state.version) {
        return state
      }

      return {
        ...state,
        updating: false,
        version: null
      }
    }

    case `${GET_WORKFLOW}_PENDING`: {
      const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = action.meta
      if (isAppCatalogPoliciesView) {
        return {
          ...state,
          appCatalogPolicies: {
            ...state.appCatalogPolicies,
            loading: true
          }
        }
      } else if (isRequestNewAppPoliciesView) {
        return {
          ...state,
          appCatalogRequestNewAppPolicies: {
            ...state.appCatalogRequestNewAppPolicies,
            loading: true
          }
        }
      } else {
        return {
          ...state,
          loading: true
        }
      }
    }

    case `${GET_WORKFLOW}_FAILED`: {
      const { meta, payload } = action
      const {
        isAppCatalogPoliciesView,
        isRequestNewAppPoliciesView,
        idWorkflow
      } = meta
      const { statusCode } = payload
      let updatedState = {}

      if (statusCode === 404) {
        const { unavailableWorkflowIds } = state
        updatedState = {
          unavailableWorkflowIds: uniq([...unavailableWorkflowIds, idWorkflow])
        }
      }

      if (isAppCatalogPoliciesView) {
        updatedState = {
          ...updatedState,
          appCatalogPolicies: {
            ...state.appCatalogPolicies,
            loading: false
          }
        }
      } else if (isRequestNewAppPoliciesView) {
        updatedState = {
          ...updatedState,
          appCatalogRequestNewAppPolicies: {
            ...state.appCatalogRequestNewAppPolicies,
            loading: false
          }
        }
      } else {
        updatedState = {
          ...updatedState,
          loading: false
        }
      }

      return {
        ...state,
        ...updatedState
      }
    }

    case `${GET_WORKFLOW}_RESPONSE`: {
      const { workflow, resources } = action.payload
      const { users = [], apps = [] } = resources || {}
      const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = action.meta

      if (isAppCatalogPoliciesView) {
        const { appCatalogPolicies } = state
        return {
          ...state,
          appCatalogPolicies: {
            ...appCatalogPolicies,
            policies: updateWorkflow(appCatalogPolicies.policies, workflow),
            resources: {
              users: { ...appCatalogPolicies.users, ...users },
              apps: { ...appCatalogPolicies.apps, ...apps }
            },
            loading: false
          }
        }
      } else if (isRequestNewAppPoliciesView) {
        const { appCatalogRequestNewAppPolicies } = state
        return {
          ...state,
          appCatalogRequestNewAppPolicies: {
            ...appCatalogRequestNewAppPolicies,
            policies: updateWorkflow(appCatalogRequestNewAppPolicies.policies, workflow),
            resources: {
              users: { ...appCatalogRequestNewAppPolicies.users, ...users },
              apps: { ...appCatalogRequestNewAppPolicies.apps, ...apps }
            },
            loading: false
          }
        }
      } else {
        return {
          ...state,
          workflows: updateWorkflow(state.workflows, workflow),
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_CONFIG}_RESPONSE`: {
      const { appsTriggers } = action.payload
      return {
        ...state,
        appsTriggers
      }
    }
    case `${GET_WORKFLOWS_ACTIONS_CONFIG}_RESPONSE`: {
      const actions = action.payload
      return {
        ...state,
        config: {
          ...state.config,
          ...actions
        }
      }
    }
    case `${GET_WORKFLOWS_PERSONALIZATION_CONFIG}_RESPONSE`: {
      const response = action.payload
      const { triggersPlaceholders, actionsPlaceholders } = response
      return {
        ...state,
        config: {
          ...state.config,
          triggersPlaceholders,
          actionsPlaceholders
        }
      }
    }
    case `${GET_WORKFLOWS_DYNAMIC_PERSONALIZATION_CONFIG}_RESPONSE`: {
      const response = action.payload
      const { idWorkflow } = action.meta
      const { actionsPlaceholders, triggerPlaceholders } = response
      return {
        ...state,
        config: {
          ...state.config,
          dynamicPlaceholders: {
            ...state.config.dynamicPlaceholders,
            [idWorkflow]: {
              actions: actionsPlaceholders,
              trigger: triggerPlaceholders
            }
          }
        }
      }
    }
    case `${GET_WORKFLOW_EXECUTIONS}_PENDING`: {
      const { reset = true, idWorkflow } = action.meta
      return {
        ...state,
        loading: reset,
        executionsState: {
          ...state.executionsState,
          [idWorkflow]: {
            ...state.executionsState[idWorkflow],
            loadingMore: !reset
          }
        }
      }
    }
    case `${GET_WORKFLOW_EXECUTIONS}_FAILED`: {
      const { idWorkflow } = action.meta
      return {
        ...state,
        loading: false,
        executionsState: {
          ...state.executionsState,
          [idWorkflow]: {
            ...state.executionsState[idWorkflow],
            loadingMore: false
          }
        }
      }
    }
    case `${GET_WORKFLOW_EXECUTIONS}_RESPONSE`: {
      const { executions, total, resources: { users } } = action.payload
      const { idWorkflow, reset = true } = action.meta
      const newExecutions = reset ? executions : (state.executions[idWorkflow] || []).concat(executions)

      return {
        ...state,
        loading: false,
        executions: {
          ...state.executions,
          [idWorkflow]: newExecutions,
          idTriggerUsersToParents: reset ? users : { ...state.executions.idTriggerUsersToParents, ...users }
        },
        executionsState: {
          ...state.executionsState,
          [idWorkflow]: {
            loadingMore: false,
            total: total
          }
        }
      }
    }
    case `${GET_WORKFLOW_ACTION_EXECUTIONS}_PENDING`: {
      return {
        ...state,
        workflowActionExecutions: {
          ...state.workflowActionExecutions,
          loading: true
        }
      }
    }
    case `${GET_WORKFLOW_ACTION_EXECUTIONS}_FAILED`: {
      return {
        ...state,
        workflowActionExecutions: {
          ...state.workflowActionExecutions,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOW_ACTION_EXECUTIONS}_RESPONSE`: {
      const { actions } = action.payload
      const { idWorkflowExecution } = action.meta

      return {
        ...state,
        workflowActionExecutions: {
          actions: {
            ...state.workflowActionExecutions.actions,
            [idWorkflowExecution]: actions
          },
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_ACTIONS_FIELDS}_PENDING`: {
      return {
        ...state,
        actionDynamicFieldsOptions: {
          ...state.actionDynamicFieldsOptions,
          loading: true
        }
      }
    }
    case `${GET_WORKFLOWS_ACTIONS_FIELDS}_FAILED`: {
      return {
        ...state,
        actionDynamicFieldsOptions: {
          ...state.actionDynamicFieldsOptions,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_ACTIONS_FIELDS}_RESPONSE`: {
      const fieldsOptions = action.payload
      const { action: actionMeta } = action.meta

      return {
        ...state,
        actionDynamicFieldsOptions: {
          ...state.actionDynamicFieldsOptions,
          [actionMeta.id]: fieldsOptions,
          loading: false
        }
      }
    }
    case CLEAR_WORKFLOW_ACTION_FIELDS: {
      const { idAction } = action.payload
      const actionDynamicFieldsOptions = omit(state.actionDynamicFieldsOptions, idAction)

      return {
        ...state,
        actionDynamicFieldsOptions
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS}_PENDING`: {
      return {
        ...state,
        triggerDynamicFieldsOptions: {
          ...state.triggerDynamicFieldsOptions,
          loading: true
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS}_FAILED`: {
      return {
        ...state,
        triggerDynamicFieldsOptions: {
          ...state.triggerDynamicFieldsOptions,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS}_RESPONSE`: {
      const fieldsOptions = action.payload
      const { trigger } = action.meta

      return {
        ...state,
        triggerDynamicFieldsOptions: {
          ...state.triggerDynamicFieldsOptions,
          [trigger.id]: fieldsOptions,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES}_PENDING`: {
      return {
        ...state,
        triggerDynamicFieldsOptionsValues: {
          ...state.triggerDynamicFieldsOptionsValues,
          loading: true
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES}_FAILED`: {
      return {
        ...state,
        triggerDynamicFieldsOptionsValues: {
          ...state.triggerDynamicFieldsOptionsValues,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOWS_TRIGGERS_FIELDS_VALUES}_RESPONSE`: {
      const valuesByField = action.payload
      const { trigger } = action.meta

      return {
        ...state,
        triggerDynamicFieldsOptionsValues: {
          ...state.triggerDynamicFieldsOptionsValues,
          [trigger.id]: valuesByField,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOW_TRIGGER_PREVIEW}_PENDING`: {
      const { idWorkflow } = action.meta
      return {
        ...state,
        triggerPreview: {
          ...state.triggerPreview,
          [idWorkflow]: {},
          loading: true
        }
      }
    }
    case `${GET_WORKFLOW_TRIGGER_PREVIEW}_FAILED`: {
      const { idWorkflow } = action.meta
      return {
        ...state,
        triggerPreview: {
          ...state.triggerPreview,
          [idWorkflow]: {
            isPreviewFailed: true
          },
          loading: false
        }
      }
    }
    case `${GET_WORKFLOW_TRIGGER_PREVIEW}_RESPONSE`: {
      const { data, triggerType } = action.payload
      const { idWorkflow } = action.meta

      return {
        ...state,
        triggerPreview: {
          ...state.triggerPreview,
          [idWorkflow]: {
            data,
            triggerType
          },
          loading: false
        }
      }
    }
    case `${DUPLICATE_WORKFLOW}_PENDING`: {
      return {
        ...state,
        updating: true
      }
    }
    case `${DUPLICATE_WORKFLOW}_FAILED`: {
      return {
        ...state,
        updating: false
      }
    }
    case `${DUPLICATE_WORKFLOW}_RESPONSE`: {
      const { workflow } = action.payload
      return {
        ...state,
        workflows: state.workflows.concat(workflow),
        updating: false
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOGS}_PENDING`: {
      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          listByIdWorkflow: {
            ...state.workflowsAuditLogs.auditLogs.listByIdWorkflow,
            loading: true
          }
        }
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOGS}_FAILED`: {
      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          listByIdWorkflow: {
            ...state.workflowsAuditLogs.auditLogs.listByIdWorkflow,
            loading: false
          }
        }
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOGS}_RESPONSE`: {
      const { auditLogs, resources } = action.payload
      const { idWorkflow } = action.meta

      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          listByIdWorkflow: {
            ...state.workflowsAuditLogs.auditLogs.listByIdWorkflow,
            loading: false,
            [idWorkflow]: { auditLogs, resources }
          }
        }
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOG_INFO}_PENDING`: {
      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          extraInfoByIdAudit: {
            ...state.workflowsAuditLogs.extraInfoByIdAudit,
            loading: true
          }
        }
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOG_INFO}_FAILED`: {
      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          extraInfoByIdAudit: {
            ...state.workflowsAuditLogs.extraInfoByIdAudit,
            loading: false
          }
        }
      }
    }
    case `${GET_WORKFLOW_AUDIT_LOG_INFO}_RESPONSE`: {
      const { auditLog } = action.payload
      const { idAudit } = action.meta

      return {
        ...state,
        workflowsAuditLogs: {
          ...state.workflowsAuditLogs,
          extraInfoByIdAudit: {
            ...state.workflowsAuditLogs.extraInfoByIdAudit,
            loading: false,
            [idAudit]: auditLog
          }
        }
      }
    }

    case `${GET_WORKFLOW_TAGS}_PENDING`: {
      return {
        ...state,
        workflowTags: {
          ...state.workflowTags,
          loading: true
        }
      }
    }
    case `${GET_WORKFLOW_TAGS}_FAILED`: {
      return {
        ...state,
        workflowTags: {
          ...state.workflowTags,
          loading: false
        }
      }
    }
    case `${GET_WORKFLOW_TAGS}_RESPONSE`: {
      const { tags } = action.payload
      return {
        ...state,
        workflowTags: {
          ...state.workflowTags,
          tags,
          loading: false
        }
      }
    }

    case `${UPDATE_WORKFLOW_TAGS}_RESPONSE`: {
      const { idTags, idWorkflow } = action.meta

      return {
        ...state,
        workflows: state.workflows.map(workflow => {
          if (workflow.id === idWorkflow) {
            return {
              ...workflow,
              tags: state.workflowTags.tags.filter(tag => idTags.includes(tag.id))
            }
          }
          return workflow
        })
      }
    }

    case `${GET_WORKFLOWS_EXECUTION_AUDIT}_RESPONSE`: {
      const { response: audits, resources = {}, total } = action.payload
      const { apps, users } = resources
      const { reset } = action.meta

      return {
        ...state,
        fullAuditLogs: {
          ...state.fullAuditLogs,
          loading: false,
          loadingMore: false,
          total,
          audits: reset ? audits : [...state.fullAuditLogs.audits, ...audits],
          resources: {
            apps: reset ? apps : { ...state.fullAuditLogs.resources.apps, ...apps },
            users: reset ? users : { ...state.fullAuditLogs.resources.users, ...users }
          }
        }
      }
    }
    case `${GET_WORKFLOWS_EXECUTION_AUDIT}_PENDING`: {
      const { reset } = action.meta
      return {
        ...state,
        fullAuditLogs: {
          ...state.fullAuditLogs,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_WORKFLOWS_EXECUTION_AUDIT}_FAILED`: {
      return {
        ...state,
        fullAuditLogs: {
          ...state.fullAuditLogs,
          loading: false,
          loadingMore: false
        }
      }
    }

    case `${GET_WORKFLOWS_EXECUTION_AUDIT_FIELD_VALUES}_RESPONSE`: {
      const { fieldValues } = action.payload
      return {
        ...state,
        fullAuditLogs: {
          ...state.fullAuditLogs,
          fieldValues: {
            ...state.fullAuditLogs.fieldValues,
            ...fieldValues
          }
        }
      }
    }

    case `${DELETE_WORKFLOW_TAG}_RESPONSE`: {
      const { idTag } = action.meta

      return {
        ...state,
        workflows: state.workflows.map(workflow => {
          return {
            ...workflow,
            tags: workflow.tags.filter(tag => tag.id !== idTag)
          }
        }),
        workflowTags: {
          ...state.workflowTags,
          tags: state.workflowTags.tags.filter(tag => tag.id !== idTag)
        }
      }
    }

    case `${CREATE_WORKFLOW_TAGS}_RESPONSE`: {
      const { tags } = action.payload

      return {
        ...state,
        workflowTags: {
          ...state.workflowTags,
          tags: state.workflowTags.tags.concat(tags)
        }
      }
    }

    case `${UPDATE_WORKFLOW_TAG}_RESPONSE`: {
      const { idTag, label } = action.meta

      return {
        ...state,
        workflowTags: {
          ...state.workflowTags,
          tags: state.workflowTags.tags.map(tag => {
            if (tag.id !== idTag) {
              return tag
            }

            return {
              ...tag,
              label
            }
          })
        }
      }
    }

    default: {
      return state
    }
  }
}

const updateWorkflow = (workflows, workflow) => {
  const modifiedWorkflows = workflows.slice()
  const workflowIndex = modifiedWorkflows.findIndex(oldWorkflow => oldWorkflow.id === workflow.id)
  if (workflowIndex === -1) {
    modifiedWorkflows.push(workflow)
  } else {
    modifiedWorkflows[workflowIndex] = {
      ...workflow
    }
  }
  return modifiedWorkflows
}

const mergeWorkflow = (workflows, workflow, idWorkflow) => {
  const modifiedWorkflows = workflows.slice()
  const workflowIndex = modifiedWorkflows.findIndex(oldWorkflow => oldWorkflow.id === idWorkflow)

  modifiedWorkflows[workflowIndex] = {
    ...modifiedWorkflows[workflowIndex],
    ...workflow
  }
  return modifiedWorkflows
}

export default workflowsReducer
