import moment from 'moment'
import { DATE_FORMATS_OPTIONS } from './stepsConfig/types'

export const getSuggestedDateFormatsBasedOnValue = (dateValue) => {
  // The following formats and logic are based on the parse_users_import lambda
  // make sure to keep them in sync on every change
  const DATE_FORMATS = ['YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY.MM.DD',
    'MM/DD/YYYY', 'MM-DD-YYYY', 'MM.DD.YYYY',
    'DD/MM/YYYY', 'DD-MM-YYYY', 'DD.MM.YYYY',
    'MM/DD/YY', 'MM-DD-YY', 'MM.DD.YY',
    'DD/MM/YY', 'DD-MM-YY', 'DD.MM.YY',
    'M/D/YY', 'M-D-YY', 'M.D.YY',
    'D/M/YY', 'D-M-YY', 'D.M.YY',
    'YYYYMMDD', 'DD MMMM YYYY', 'MMM DD, YYYY']
  const FORGIVING_DATE_AND_TIME_FORMATS = ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'MM-DD-YYYY HH:mm:ss']

  const validDateFormats = DATE_FORMATS.filter(format => {
    return moment(dateValue, format, true).isValid()
  })

  if (validDateFormats.length) {
    return validDateFormats
  }

  const isFoundValidDateAndTimeFormat = moment(dateValue, FORGIVING_DATE_AND_TIME_FORMATS, false).isValid()

  if (isFoundValidDateAndTimeFormat) {
    return []
  }

  return undefined
}

export const getSelectedDateFormat = (suggestedDateFormat) => {
  if (!suggestedDateFormat) {
    return undefined
  }

  if (suggestedDateFormat.length === 0) {
    return ''
  } else if (suggestedDateFormat.length === 1) {
    return suggestedDateFormat[0]
  } else {
    return getDateFormatsByStructure(suggestedDateFormat).daysPriorMonths
  }
}

export const getDateFormatsByStructure = (dateFormats): { [DATE_FORMATS_OPTIONS.DAYS_PRIOR_MONTHS]: string, [DATE_FORMATS_OPTIONS.MONTHS_PRIOR_DAYS]: string } => {
  const [format1, format2] = dateFormats
  if (/DD.*MM/.test(format1)) {
    return { daysPriorMonths: format1, monthsPriorDays: format2 }
  } else {
    return { daysPriorMonths: format2, monthsPriorDays: format1 }
  }
}
