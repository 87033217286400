import React from 'react'
import PropTypes from 'prop-types'
import { FormElement, Link } from '@toriihq/design-system'

const FormGroup = ({ disabled, label, labelAction, details, error, children, isRequired, tooltip, learnMoreUrl }) => {
  return (
    <div style={{ marginBottom: '24px' }}>
      <FormElement
        label={label}
        description={details}
        labelHelper={tooltip}
        labelAction={labelAction?.component ? labelAction.component : learnMoreUrl ? <Link target='_blank' href={learnMoreUrl}>Learn more</Link> : null}
        errorMessage={error}
        required={isRequired}
        disabled={disabled}
      >
        {children}
      </FormElement>
    </div>
  )
}

FormGroup.propTypes = {
  label: PropTypes.string,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  labelAction: PropTypes.object,
  details: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  learnMoreUrl: PropTypes.string,
  tooltip: PropTypes.element
}

export default FormGroup
