import React from 'react'
import PropTypes from 'prop-types'
import Select from '../select'
import EnableFor from '../enableFor'
import { SCOPES } from '@root/constants'
import Placeholder from '../placeholder'
import Input from '../form/input'
import FormGroup from '../form/formGroup'
import ToriiPopup from '../popups/ToriiPopupV2'
import successImage from './images/success.svg'
import ToriiToggle from '@components/toriiToggle'
import * as Style from './style'
import TermsAndConditionsPopup from '@components/popups/termsAndConditionsPopup'
import { FEATURES } from '@root/shared/features'
import ToggleOfFeature from '@components/toggleOfFeature'
import { H4, Stack, Link, LinkSize, Subtitle1 } from '@toriihq/design-system'
import BlockAccessToggle from './blockAccessToggle'

const termsAndConditionsText = 'By moving the toggle you hereby acknowledge and agree that you are enabling the Benchmarking feature, and you hereby acknowledges and agrees that Torii may aggregate and anonymize your Customer Data (as such term is defined in the services agreement between Torii and your organization) in order to provide the Benchmarking feature to all customers. If you are not signing on your own behalf, You represent that you have the power and authority to bind your organization to these terms and that this consent shall supersede any inconsistent term in any other agreement between you and/or your organization and Torii.'

class OrgGeneralSettings extends React.Component {
  state = {
    isDefaultCurrencyPopupOpen: false,
    isLicenseBenchmarkPopupOpen: false
  }

  onDefaultCurrencyChange = (option) => {
    const { idOrg, updateOrg } = this.props
    const defaultCurrency = option.value
    updateOrg({ idOrg, defaultCurrency })
    this.setState({ isDefaultCurrencyPopupOpen: true })
  }

  onOpenLicenseBenchmarkPopup = () => {
    this.setState({ isLicenseBenchmarkPopupOpen: true })
  }

  onCompanyNameChange = (companyName) => {
    const { idOrg, updateOrg } = this.props
    if (companyName) {
      updateOrg({ idOrg, companyName })
    }
  }

  benchmarkDataHelp = () => <Link size={LinkSize.Medium} href='https://support.toriihq.com/hc/en-us/articles/11556671557147' target='_blank' rel='noopener noreferrer'>Learn More</Link>

  onCloseDefaultCurrencyPopup = () => {
    this.setState({ isDefaultCurrencyPopupOpen: false })
  }
  onCloseLicenseBenchmarkPopup = () => {
    this.setState({ isLicenseBenchmarkPopupOpen: false })
  }

  onGeolocationToggle = isGeoLocationAllowed => {
    const { idOrg, updateOrg } = this.props
    updateOrg({ idOrg, isGeoLocationAllowed: isGeoLocationAllowed ? 1 : 0 })
  }

  onBenchmarkToggle = isBenchmarkAllowed => {
    const { idOrg, updateOrg } = this.props
    updateOrg({ idOrg, isBenchmarkAllowed: isBenchmarkAllowed ? 1 : 0 })
  }

  onBlockAccessToggle = isBlockThirdPartyAppsEnabled => {
    const { idOrg, updateOrg } = this.props
    updateOrg({ idOrg, isBlockThirdPartyAppsEnabled: isBlockThirdPartyAppsEnabled ? 1 : 0 })
  }

  render () {
    const {
      loading,
      defaultCurrency,
      companyName,
      idOrg,
      isGeoLocationAllowed,
      isBenchmarkAllowed,
      displayCurrencyOptions,
      isBlockThirdPartyAppsEnabled
    } = this.props

    return (
      <Stack gap='space-300'>
        <H4>General</H4>
        <Stack gap='space-200'>
          <div {...Style.input}>
            <FormGroup label='Organization ID'>
              <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                <Input
                  type='text'
                  className={Style.input}
                  value={idOrg}
                  readOnly
                  round
                />
              </Placeholder>
            </FormGroup>

            <FormGroup label='Company name'>
              <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                  <Input
                    type='text'
                    overrideStyle={Style.input}
                    defaultValue={companyName}
                    onBlur={e => this.onCompanyNameChange(e.target.value)}
                    round
                  />
                </EnableFor>
              </Placeholder>
            </FormGroup>

            <FormGroup label='Displayed currency'>
              <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                  <Select
                    options={displayCurrencyOptions}
                    placeholder={<div>Select currency</div>}
                    value={defaultCurrency}
                    onChange={this.onDefaultCurrencyChange}
                    clearable={false}
                    searchable={false}
                    key='default_currency_fields_select'
                    name='default_currency_fields_select'
                    openOnFocus
                  />
                </EnableFor>
              </Placeholder>
            </FormGroup>
          </div>
          <div style={{ width: 530 }}>
            <Stack gap='space-200'>
              <Stack gap='space-100'>
                <Subtitle1>Geolocation</Subtitle1>
                <FormGroup>
                  <div {...Style.geolocationSubSection}>
                    <div>Display users' geolocation data</div>
                    <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                      <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                        <ToriiToggle
                          data-testid='isGeoLocationAllowed'
                          id='isGeoLocationAllowed'
                          checked={Boolean(isGeoLocationAllowed)}
                          onToggle={this.onGeolocationToggle}
                        />
                      </EnableFor>
                    </Placeholder>
                  </div>
                </FormGroup>
              </Stack>
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <Subtitle1>Benchmark Data</Subtitle1>
                  {this.benchmarkDataHelp()}
                </div>
                <FormGroup details={<div>By enabling this feature, you are agreeing to the <Link onClick={this.onOpenLicenseBenchmarkPopup}>Terms and conditions</Link>.</div>}>
                  <div {...Style.benchmarkSection}>
                    <div {...Style.benchmarkTitlesAndToggle}>
                      <div>Display license cost benchmarks</div>
                      <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
                        <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
                          <ToggleOfFeature
                            feature={FEATURES.LICENSES.COMPONENTS.BENCHMARK}
                            data-testid='isBenchmarkAllowed'
                            id='isBenchmarkAllowed'
                            checked={Boolean(isBenchmarkAllowed)}
                            onToggle={this.onBenchmarkToggle}
                          />
                        </EnableFor>
                      </Placeholder>
                    </div>
                  </div>
                </FormGroup>
              </div>
              <BlockAccessToggle
                idOrg={idOrg}
                loading={loading}
                isBlockThirdPartyAppsEnabled={isBlockThirdPartyAppsEnabled}
                onBlockAccessToggle={this.onBlockAccessToggle}
              />
            </Stack>
          </div>
          <ToriiPopup isOpen={this.state.isDefaultCurrencyPopupOpen} onCloseAction={this.onCloseDefaultCurrencyPopup}>
            <ToriiPopup.Header header={`Displayed currency updated`} />
            <ToriiPopup.Content>
              <div {...Style.popupBody}>
                <div>
                  <div {...Style.popupHeadline}>The displayed currency has been updated successfully.</div>
                  <div {...Style.popupSecondaryText}>Please allow a few minutes for expenses to reflect the new currency.</div>
                </div>
                <div>
                  <img src={successImage} alt='Success' />
                </div>
              </div>
            </ToriiPopup.Content>
            <ToriiPopup.Footer
              showCancelButton={false}
              mainButtonAction={this.onCloseDefaultCurrencyPopup}
              mainButtonText='Got it'
            />
          </ToriiPopup>
          <TermsAndConditionsPopup
            isOpen={this.state.isLicenseBenchmarkPopupOpen}
            onCloseAction={this.onCloseLicenseBenchmarkPopup}
            text={termsAndConditionsText}
          />
        </Stack>
      </Stack>
    )
  }
}

OrgGeneralSettings.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default OrgGeneralSettings
