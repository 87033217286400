import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { List, AutoSizer, WindowScroller } from 'react-virtualized'
import moment from 'moment'
import { Icon, Tooltip, AlertBox, AlertBoxType, EmptyState, Divider } from '@toriihq/design-system'
import withFixedColumns from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import withDraggableColumns from 'react-table-hoc-draggable-columns'
import 'react-table-hoc-draggable-columns/dist/styles.css'
import isString from 'lodash/isString'
import range from 'lodash/range'
import isFunction from 'lodash/isFunction'
import debounce from 'lodash/debounce'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import isBoolean from 'lodash/isBoolean'
import { TABLES, WHITE_SPACE } from '@root/constants'
import { fontSize, fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { getValue, formatNumber } from '@shared/utils'
import Analytics from '@helpers/analytics'
import exportCSV from '@helpers/exportCSV'
import { applyFilters, opsWithValuesDropdown } from '@lenses/filters'
import { getDisplayName } from '@lenses/users'
import ColumnsSelection from '../columnsSelection'
import CheckButton from '../checkButton'
import Placeholder from '../placeholder'
import EnableFor from '../enableFor'
import NoResultsFound from './noResultsFound'
import TableSearch from './tableSearch'
import TableFilter from './tableFilter'
import TableCSVButton from './tableCSVButton'
import TableViews from './tableViews'
import TableBulkEdit from './tableBulkEdit'
import Spinner from '@media/spinner.gif'
import './customStyle.less'
import TableImportButton from './tableImportButton'
import DraggableRowsTable from '@components/table/draggableRowsTable'
import compact from 'lodash/compact'
import TableCustomSelect from '@components/table/tableCustomSelect'
import ButtonOfFeature from '@components/buttonOfFeature'
import NoResults from '@media/no-results.svg'
import isEqual from 'lodash/isEqual'

export const SELECTABLE_COLUMN_ID = 'select'
export const DRAGGABLE_ROW_COLUMN_ID = 'draggableRow'

const ReactTableDraggableColumns = withDraggableColumns(ReactTable)

ReactTableDraggableColumns.defaultProps = ReactTableDraggableColumns.defaultProps || {}

const ReactTableFixedAndDragableColumns = withFixedColumns(ReactTableDraggableColumns)

const CSS = {
  virtualizedContainer: css({
    outline: 'none',
    ' .ReactVirtualized__Grid__innerScrollContainer': {
      outline: 'none'
    }
  }),
  stripedRow: css({
    '&.-odd': {
      background: `${colors.white} !important`
    },
    '&.-even': {
      background: `${colors.lightBlue3} !important`
    }
  }),
  row: css({
    '&.rt-tr:hover': {
      background: `${colors.lightBlue3} !important`,
      ' .rthfc-td-fixed': {
        background: `${colors.lightBlue3} !important`
      },
      ' .hoverRenderer': {
        display: 'inline !important'
      },
      ' .valueRenderer': {
        display: 'none'
      },
      ' .dotsField': {
        opacity: 1
      }
    }
  }),
  alignedField: css({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  alignedHeader: css({
    alignItems: 'right',
    justifyContent: 'flex-end'
  }),
  tableHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1px 24px'
  }),
  filtersContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12
  }),
  buttonContainer: css({
    marginRight: '10px'
  }),
  tableHeaderTitle: css({
    marginRight: '20px',
    marginLeft: '0px',
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semiBold,
    color: colors.darkText,
    maxWidth: '100%'
  }),
  headerOptions: css({
    display: 'flex',
    alignItems: 'center',
    ' > div, > button, > span': {
      marginLeft: '10px',
      marginRight: 0
    }
  }),
  showingResults: css(texts.subheading, {
    color: colors.grey1,
    paddingBottom: '10px'
  }),
  resizer: css({
    background: colors.grey3,
    borderRight: `6px solid ${colors.white}`,
    borderLeft: `6px solid ${colors.white}`,
    opacity: 0,
    zIndex: '2 !important',
    transition: 'opacity .2s',
    '.rt-th:hover &, .rt-resizing &': {
      opacity: 1
    }
  }),
  loadingMore: css({
    width: '100%',
    textAlign: 'center',
    margin: '10px 0 10px 0'
  }),
  descendingArrow: css({
    transform: `rotateX(180deg)`
  }),
  arrow: css({
    margin: 'auto 0 auto 5px',
    fontSize: '13px',
    lineHeight: '21px'
  }),
  dotsField: css({
    display: 'inline-flex',
    opacity: 0,
    ' .icon': {
      marginLeft: '10px'
    }
  })
}

class Table extends React.Component {
  constructor (props) {
    super(props)
    const { selectable, draggableRows, columns } = props

    this.state = {
      search: '',
      sort: '',
      selectedRows: new Set(this.props.selectedItems),
      isAllSelected: false,
      rowsToShow: this.props.pageSize,
      forceShowSpinner: false,
      columns: compact([ (draggableRows ? this.draggableRowColumn : null), (selectable ? this.selectableColumn : null), ...columns ])
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.selectedItems !== prevProps.selectedItems) {
      this.setState({ selectedRows: new Set(this.props.selectedItems) })
    }

    if (this.props.data && prevProps.data && this.props.data.length !== prevProps.data.length) {
      this.setState({ forceShowSpinner: false })
    }

    if (!isEqual(this.props.filtersOptions, prevProps.filtersOptions)) {
      this.fetchFieldValues()
    }

    if (this.props.columns !== prevProps.columns ||
        this.props.selectable !== prevProps.selectable) {
      this.setState({ columns: compact([ (this.props.draggableRows ? this.draggableRowColumn : null), (this.props.selectable ? this.selectableColumn : null), ...this.props.columns ]) })
    }
  }

  noop = () => null

  renderCheckbox = (isSelected, id = '', onChange, disabled, indeterminate) => {
    return <CheckButton disabled={disabled} id={id} isChecked={isSelected} onChange={onChange} indeterminate={indeterminate} />
  }

  getHeader () {
    const { header } = this.props

    return isFunction(header) ? header(this.props) : header
  }

  clearSelection = () => {
    const { selectedRows } = this.state
    selectedRows.clear()
    this.setState({ isAllSelected: false, selectedRows })
  }

  selectableColumn = {
    id: SELECTABLE_COLUMN_ID,
    Header: () => {
      const { tableKey } = this.props
      const data = this.getVisibleData()
      const isAllSelected = this.state.isAllSelected || (data.length > 0 && data.length === this.state.selectedRows.size)
      return data.length ? this.renderCheckbox(isAllSelected, -1, (id, isAllSelected) => {
        const { selectedRows } = this.state
        if (isAllSelected) {
          Analytics.track('Clicked to select all rows', {
            'Table': (TABLES[tableKey] || {}).name
          })
          data.forEach(u => { selectedRows.add(u[this.props.selectId]) })
        } else {
          selectedRows.clear()
        }
        this.setState({ isAllSelected, selectedRows })
        this.props.onSelectChange(Array.from(selectedRows))
      }, null, !!this.state.selectedRows.size && !isAllSelected) : ''
    },
    Cell: (data) => {
      const { selectId, tableKey } = this.props
      return this.renderCheckbox(!!this.state.selectedRows.has(data.row[selectId]), data.row[selectId], (id, isChecked) => {
        const { selectedRows } = this.state
        let isAllSelected
        if (isChecked) {
          Analytics.track('Clicked to select row', {
            'Table': (TABLES[tableKey] || {}).name
          })
          selectedRows.add(id)
        } else {
          selectedRows.delete(id)
          isAllSelected = false
        }
        const state = omitBy({ selectedRows, isAllSelected }, isUndefined)
        this.setState(state)
        this.props.onSelectChange(Array.from(selectedRows))
      }, null, null)
    },
    sortable: false,
    width: 36,
    style: {
      height: 36,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      flexGrow: 1,
      flexBasis: 'auto',
      padding: '0',
      display: 'flex'
    },
    hideFromCSV: true,
    resizable: false
  }

  draggableRowColumn = {
    id: DRAGGABLE_ROW_COLUMN_ID,
    Header: '',
    style: { display: 'flex', alignItems: 'center' },
    Cell: () => {
      const { loading, scopesForDraggableRows } = this.props

      const DotsComponent = ({ disabled }) => {
        if (disabled) {
          return <div />
        }

        return <div className='dotsField' {...CSS.dotsField}>
          { !loading && <Icon name='Drag' /> }
        </div>
      }

      return (
        <EnableFor scopes={scopesForDraggableRows} >
          <DotsComponent />
        </EnableFor>
      )
    },
    sortable: false,
    width: 33,
    hideFromCSV: true,
    resizable: false
  }

  calculateColumnShow () {
    const { columns } = this.state
    return columns.forEach(column => {
      if (column.showFunc) {
        column.show = column.showFunc()
      }

      return column
    })
  }

  getLoadingColumns (columns) {
    return columns
      .filter(column => column.show !== false)
      .map(column => {
        const isGroupedColumns = column.columns && column.columns.length > 0
        if (isGroupedColumns) {
          return {
            ...column,
            columns: column.columns ? column.columns.map(column => ({ ...column, Cell: Loader })) : null
          }
        }

        return {
          ...column,
          Cell: Loader
        }
      })
  }

  onSearch = (e) => {
    const { onSearch } = this.props
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
    onSearch(value)
    this.onSearchStopped()
  }

  onSearchStopped = debounce(() => {
    const { tableKey } = this.props
    const { search } = this.state
    if (!search) {
      return
    }

    Analytics.track('Search Table', {
      'Table': (TABLES[tableKey] || {}).name,
      'Term': search
    })
  }, 1000)

  searchFilterMethod = (row) => {
    const { searchFilterMethod } = this.props
    const { search } = this.state
    if (!search) {
      return true
    }

    return searchFilterMethod(row, search.toLowerCase())
  }

  onSortedChange = (sorting) => {
    const { tableKey, sortTable, onSortedChangeCB, supportViews, shareViewPreferences } = this.props
    if (tableKey) {
      this.setState({ sort: sorting })
      sortTable({ tableKey, sortingInfo: sorting, supportViews, shareViewPreferences })
    }
    onSortedChangeCB(sorting)
  }

  onFiltersChange = (filters) => {
    const { tableKey, filterTable, onFilterChange, manual, supportViews, shareViewPreferences } = this.props
    if (tableKey) {
      filterTable({ tableKey, filters, supportViews, shareViewPreferences })

      this.onFilterStopped()
    }

    this.clearSelection()
    onFilterChange(filters)
    if (manual) {
      this.fetchFieldValues(filters)
    }
  }

  fetchFieldValues = (filters = this.props.filters) => {
    const { filterOptionsValuesPerKey, fetchFieldValues } = this.props
    filters.forEach(filter => {
      if (opsWithValuesDropdown.includes(getValue(filter.op)) && !filterOptionsValuesPerKey[getValue(filter.key)]) {
        fetchFieldValues(getValue(filter.key))
      }
    })
  }

  onFilterStopped = debounce(() => {
    const { tableKey, filters } = this.props

    Analytics.track('Filter Table', {
      'Table name': (TABLES[tableKey] || {}).name,
      'Filters': filters
    })
  })

  onExportToCSV = async () => {
    const { tableKey, exportFunction, defaultSort, filters, exportPrefix, addColumnsToDefaultExport } = this.props

    if (exportFunction) {
      exportFunction({ sort: (this.state.sort || defaultSort), query: this.state.search, filters })
    } else {
      const reactTableState = this.reactTable.wrappedInstance.getResolvedState() || {}
      const { allDecoratedColumns = [], sortedData = [] } = reactTableState
      const exportableColumns = allDecoratedColumns.filter(column => {
        const show = ((column.showFunc ? column.showFunc() : (column.show !== false)))
        const hideFromCSV = isFunction(column.hideFromCSV) ? column.hideFromCSV() : column.hideFromCSV
        return column.forceCSV || Boolean(!hideFromCSV && show)
      })
      let columnNames = exportableColumns.map(column => (column.textHeader || column.Header.props.children))
      let data = sortedData.map(row => {
        return exportableColumns.map((column) => {
          return column.textValue ? column.textValue({ ...row, value: row[column.id] }) : row[column.id]
        })
      })

      if (addColumnsToDefaultExport) {
        const addColumnsToDefaultExportResult = addColumnsToDefaultExport({ columnNames, data })
        columnNames = addColumnsToDefaultExportResult.columnNames
        data = addColumnsToDefaultExportResult.data
      }
      exportCSV(`${exportPrefix ?? tableKey}_${moment().format('DD_MMMM_YYYY')}.csv`, data, columnNames)
    }

    Analytics.track('Export Table', {
      'Table': (TABLES[tableKey] || {}).name,
      'Export format': 'CSV',
      'Filters': filters?.length ? 'Yes' : 'No',
      'Search': this.state.search ? 'Yes' : 'No'
    })
  }

  onCSVUsersImport = async () => {
    const { onCSVUsersImport } = this.props
    onCSVUsersImport()
  }

  renderThComponent = ({ toggleSort, children, sortOrder, style, className }) => {
    const isDescending = sortOrder === 'desc'
    const arrowStyle = css(CSS.arrow, isDescending && CSS.descendingArrow)
    return (
      <div style={style} className={`${className} rt-th`} onClick={toggleSort}>
        {children}
        {sortOrder ? <span {...arrowStyle}>{'▴'}</span> : null}
      </div>
    )
  }

  injectTheadThSortOrder = (table, _, column) => {
    const { overrideTheadThStyle = {}, draggable } = this.props
    const sortOrder = table.sorted.find(sorted => sorted.id === column.id)
    const draggableStyle = draggable ? { padding: '14px 10px' } : { padding: '14px 20px' }
    return {
      style: {
        textAlign: 'left',
        display: 'flex',
        border: 0,
        color: sortOrder && colors.darkText,
        boxShadow: 'none',
        ...draggableStyle,
        ...column.style,
        ...overrideTheadThStyle
      },
      sortOrder: sortOrder && (sortOrder.desc ? 'desc' : 'asc')
    }
  }

  setTheadStyle = () => {
    const { overrideTheadStyle = {} } = this.props
    return {
      className: texts.subheading.toString(),
      style: {
        boxShadow: 'none',
        borderBottom: `1px solid ${colors.grey3}`,
        padding: '0',
        cursor: 'pointer',
        ...overrideTheadStyle
      }
    }
  }

  setTableStyle = ({ data }) => {
    return {
      style: {
        flex: 'initial',
        height: data.length > 0 ? 'auto' : 0,
        overflow: data.length > 0 ? 'auto' : 'hidden'
      }
    }
  }

  setTrGroupStyle = (state, rowInfo) => {
    const { overrideTrGroupStyle } = this.props

    const key = rowInfo.row.uid || rowInfo.row.id
    const keyObject = key ? { key } : {}
    return {
      ...keyObject,
      style: {
        padding: 0,
        borderBottom: `1px solid ${colors.grey3}`,
        ...overrideTrGroupStyle
      }
    }
  }

  setTheadGroupThStyle = () => {
    const { overrideTheadGroupThStyle = {} } = this.props

    return {
      style: {
        textAlign: 'left',
        padding: 0,
        ...overrideTheadGroupThStyle
      }
    }
  }

  setTheadGroupStyle = () => {
    const { overrideThGroupStyle = {} } = this.props

    return {
      className: texts.heading.toString(),
      style: {
        textAlign: 'left',
        padding: '0',
        border: 'none',
        backgroundColor: 'inherit',
        ...overrideThGroupStyle
      }
    }
  }

  setThStyle = () => {
    return {
      style: {
        padding: '0 20px'
      }
    }
  }

  setTdStyle = (state, rowInfo, column) => {
    const { overrideTdStyle = {}, draggable } = this.props
    const key = column.id || column.accessor
    const keyObject = key ? { key } : {}
    const isFixedColumn = Boolean(column.fixed)
    const draggableStyle = draggable ? { padding: '10px' } : { padding: '10px 20px' }

    return {
      ...keyObject,
      style: {
        border: 0,
        color: colors.black,
        background: isFixedColumn ? colors.white : 'inherit',
        ...draggableStyle,
        ...overrideTdStyle
      }
    }
  }

  getOnTrClickProps = (rowInfo) => {
    const { onTrClick } = this.props
    const { row } = rowInfo

    return onTrClick ? { onClick: () => { onTrClick({ row }) } } : {}
  }

  getTrStyleProps = (state, rowInfo) => {
    const { striped, setTrStyleClassName, overrideTrStyle, overrideTrStyleRule, selectId, highlightStyle = {} } = this.props
    const { selectedRows } = this.state
    const highlight = (overrideTrStyleRule ? overrideTrStyleRule(rowInfo) : selectedRows.has(rowInfo.row[selectId])) ? { ...highlightStyle } : {}
    return {
      className: [striped ? CSS.stripedRow.toString() : CSS.row.toString(), setTrStyleClassName].join(' '),
      style: {
        alignItems: 'center',
        alignContent: 'center',
        minHeight: '60px',
        ...highlight,
        ...overrideTrStyle
      }
    }
  }

  getTrProps = (state, rowInfo) => {
    const trStyleProps = this.getTrStyleProps(state, rowInfo)
    const onTrClickProp = this.getOnTrClickProps(rowInfo)
    return {
      ...trStyleProps,
      ...onTrClickProp
    }
  }

  setResizerProps = () => {
    const { overrideResizerStyle = {} } = this.props

    return {
      className: CSS.resizer.toString(),
      style: {
        width: '13px',
        right: 0,
        ...overrideResizerStyle
      }
    }
  }

  setNoDataProps = () => {
    return {
      style: {
        pointerEvents: 'all',
        top: '30%'
      }
    }
  }

  renderTbodyComponent = ({ children, style }) => {
    const [rows, ...rest] = children
    return (
      <div className='rt-tbody' style={{ ...style, height: '100%' }}>
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop, scrollToIndex }) => (
            <AutoSizer disableHeight>
              {
                ({ width }) => {
                  return (
                    <List
                      autoHeight
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      scrollTop={scrollTop}
                      scrollToIndex={scrollToIndex}
                      className={CSS.virtualizedContainer.toString()}
                      width={width}
                      height={height}
                      rowCount={rows.length}
                      rowHeight={60}
                      rowRenderer={({ index, key, style }) => React.cloneElement(rows[index], { key, style })}
                    />
                  )
                }
              }
            </AutoSizer>
          )}
        </WindowScroller>
        {rest}
      </div>
    )
  }

  noDataText = () => {
    const { emptyStateMessage, filters } = this.props
    const { search } = this.state

    if (search) {
      return <NoResultsFound search={search} />
    }

    if (filters && filters.length > 0) {
      return <EmptyState
        image={<img src={NoResults} alt='No results found' />}
        description={`No results found for the selected filters`}
      />
    }

    return emptyStateMessage ?? <EmptyState
      image={<img src={NoResults} alt='No data' />}
      description={`No data`}
    />
  }

  filterBySearchMethod = (data) => {
    const { search } = this.state

    if (!search) {
      return data
    }

    return data.filter(this.searchFilterMethod)
  }

  getVisibleData = () => {
    const { clientPaging, manual } = this.props

    const data = this.getData()
    if (manual && clientPaging) {
      return data.slice(0, this.state.rowsToShow)
    }

    return data
  }

  getData = () => {
    const { data, loading, filterable, filters, manual, allColumns } = this.props
    const { columns } = this.state

    if (loading) {
      return loadingData
    }

    if (manual) {
      return data
    }

    const columnsToFilterBy = allColumns || columns

    const filtersAccessors = columnsToFilterBy.filter((column) => isFunction(column.filterAccessor) || isFunction(column.accessor))
      .reduce((arr, column) => ({ ...arr, [column.id]: isFunction(column.filterAccessor) ? column.filterAccessor : column.accessor }), {})

    const filtered = filterable ? applyFilters(data, filters, filtersAccessors) : data
    return this.filterBySearchMethod(filtered)
  }

  componentDidMount () {
    const { clientPaging, manual, filters, tableKey, supportViews, getTableViews, defaultSort, origDefaultSort } = this.props
    if (clientPaging || manual) {
      const el = this.props.scrollObjectId ? document.getElementById(this.props.scrollObjectId) : document.getElementsByClassName('mainWrapper')[0]
      el && el.addEventListener('scroll', this.onWindowScroll)
    }
    if (manual) {
      this.fetchFieldValues(filters)
    }

    if (supportViews) {
      getTableViews({ tableKey })
    }

    if (defaultSort.length !== origDefaultSort.length) {
      this.onSortedChange(defaultSort)
    }
  }

  componentWillUnmount () {
    const el = this.props.scrollObjectId ? document.getElementById(this.props.scrollObjectId) : document.getElementsByClassName('mainWrapper')[0]
    el && el.removeEventListener('scroll', this.onWindowScroll)
  }

  changePageSize = debounce(() => {
    const next = this.state.rowsToShow + this.props.pageSize
    this.setState({ rowsToShow: Math.min(next, this.getData().length || next) })
  }, 500, { leading: true, trailing: true })

  isScrolledToBottom = () => {
    const element = this.props.scrollObjectId ? document.getElementById(this.props.scrollObjectId) : document.getElementsByClassName('mainWrapper')[0]
    if (element) {
      return (this.lastWindowScrollY < element.scrollTop && element.clientHeight + element.scrollTop + 1000 > element.scrollHeight)
    }
    return (this.lastWindowScrollY < window.scrollY && window.innerHeight + window.scrollY + 1000 > document.body.scrollHeight)
  }

  onWindowScroll = () => {
    const { clientPaging, fetchData, data, totalCount, loadingMore } = this.props
    if (this.isScrolledToBottom()) {
      if (clientPaging) {
        this.changePageSize()
      } else if (data.length < totalCount && !loadingMore) {
        this.setState({ forceShowSpinner: true }, fetchData)
      }
    }

    const element = this.props.scrollObjectId && document.getElementById(this.props.scrollObjectId)
    this.lastWindowScrollY = element ? element.scrollTop : window.scrollY
  }

  resetRowsToShow = () => {
    this.setState({ rowsToShow: this.props.pageSize })
  }

  resetRowsSelection = () => {
    this.setState({ selectedRows: new Set(), isAllSelected: false })
  }

  onViewChanged = () => {
    this.resetRowsToShow()
    this.resetRowsSelection()
  }

  displayResults = ({ filterable, forceShowNumberOfResults, searchable, search, selectable, selectedRows, totalCount, filteredData, customSelect, loading, ignoreWhitespace }) => {
    const shouldShowMessage = forceShowNumberOfResults || filterable || search || selectedRows.size > 0
    if (shouldShowMessage) {
      let content
      if (selectedRows.size) {
        content = `${selectedRows.size} of ${totalCount !== undefined ? totalCount : filteredData.length} selected`
      } else if (((totalCount !== undefined && totalCount > 0) || filteredData.length > 0)) {
        const formattedCount = formatNumber(totalCount !== undefined ? totalCount : filteredData.length)
        content = loading ? WHITE_SPACE : `Showing ${formattedCount} results`
      }

      return (<div {...CSS.showingResults}>
        {content}
      </div>)
    }

    const shouldShowWhitespace = (filterable || searchable || selectable || customSelect) && !ignoreWhitespace
    if (shouldShowWhitespace) {
      return <div {...CSS.showingResults}>{WHITE_SPACE}</div>
    }

    return null
  }

  secondaryCustomSelectInternalOnChange = (selectedOption) => {
    const { secondaryCustomSelectOnChange } = this.props
    const locationPathWithoutSearchParam = window.location.pathname
    window.history.replaceState(null, '', locationPathWithoutSearchParam)
    secondaryCustomSelectOnChange(selectedOption)
  }

  customSelectInternalOnChange = ({ selectedOption, isSecondaryCustomSelect }) => {
    const { tableKey, tableCustomSelectChange, customSelectOnChange, appName } = this.props

    tableCustomSelectChange({ tableKey, selectedOption, appName, isSecondaryCustomSelect })
    isSecondaryCustomSelect ? this.secondaryCustomSelectInternalOnChange(selectedOption) : customSelectOnChange(selectedOption)
  }

  getColumnsNamesForSort = (columns, useCustomId = false) => {
    return columns
      .filter(column => !isBoolean(column.show) || (isBoolean(column.show) && column.show))
      .map(column => (useCustomId && column.idForColumnsOrder) || column.id || (isString(column.accessor) ? column.accessor : null))
      .filter(Boolean)
  }

  onColumnOrderChange = (columns) => {
    const { tableKey, supportViews, configurableColumnsOptions, configureTableColumns, shareViewPreferences } = this.props

    const columnNames = this.getColumnsNamesForSort(columns, true)

    configureTableColumns(tableKey, columnNames, configurableColumnsOptions, supportViews, shareViewPreferences)
  }

  getDraggableColumns = (columns) => {
    const { isReadOnlyView, draggable } = this.props

    if (isReadOnlyView || !draggable) {
      return []
    }

    return this.getColumnsNamesForSort(columns).filter(column => column !== SELECTABLE_COLUMN_ID && column !== DRAGGABLE_ROW_COLUMN_ID)
  }

  getCustomButton = () => {
    const { customButton } = this.props

    return customButton && <Tooltip
      placement='top'
      hide={!customButton.tooltipMessage}
      label={customButton.tooltipMessage}>
      <EnableFor scopes={customButton.scopes} allowForToriiAdmin>
        {customButton.button || <ButtonOfFeature feature={customButton.feature} onClick={customButton.onClick} disabled={customButton.isDisabled} label={customButton.text} tooltipPlacement={customButton.tooltipMessage ? 'bottom' : 'top'} />}
      </EnableFor>
    </Tooltip>
  }

  getTbodyComponent = ({ children, data }) => {
    const { scopesForDraggableRows, onRowsOrderChanged } = this.props

    return (
      <EnableFor scopes={scopesForDraggableRows} >
        <DraggableRowsTable children={children} data={data} onRowsOrderChanged={onRowsOrderChanged} />
      </EnableFor>
    )
  }

  render () {
    const {
      data,
      hideTableContent,
      loading,
      resizable,
      searchable,
      searchFilterMethod,
      searchPlaceholder,
      tableKey,
      filterable,
      filters,
      filtersOptions,
      filterOptionsValuesPerKey,
      isFiltersOpen,
      toggleFilterTable,
      defaultSort,
      style,
      isSmallScreen,
      virtualized,
      emptyStateMessage,
      configurableColumns,
      configurableColumnsOptions,
      exportable,
      showImportCSV,
      customButton,
      totalCount,
      forceShowSearch,
      forceShowNumberOfResults,
      fixedColumnOrder,
      tableHeaderStyle,
      customHeaderOnSelect,
      extraHeaderComponent,
      onHeaderClick,
      pageSize,
      clientPaging,
      manual,
      supportBulkEdit,
      supportViews,
      selectable,
      customSelect,
      customSelectOptions,
      customSelectSelectedValue,
      secondaryCustomSelect,
      secondaryCustomSelectOptions,
      secondaryCustomSelectSelectedValue,
      isReadOnlyView,
      readOnlyTooltip,
      sortable,
      bulkEditFields,
      onBulkSubmit,
      bulkEditFieldKey,
      bulkEditFieldName,
      showBulkDelete,
      onBulkDelete,
      itemsName,
      alertMessage,
      userTablePreferences,
      allowedScopes = [],
      hasInvalidFilters,
      customSelectOptionRenderer,
      editableViews,
      ignoreWhitespace,
      importCSVScopes,
      onRowsOrderChanged,
      draggableRows,
      scopesForDraggableRows,
      importCSVPopoverComponent,
      alternativeHeader,
      ...rest
    } = this.props

    const { columns, rowsToShow, forceShowSpinner, selectedRows, search } = this.state

    const Header = this.getHeader()
    this.calculateColumnShow(columns)
    !fixedColumnOrder && sortColumns(columns, userTablePreferences)
    const virtualizationProps = virtualized ? { TbodyComponent: this.renderTbodyComponent } : {}
    const showSearch = ((data && data.length > 0) || forceShowSearch) && searchable
    const showFilter = ((data && data.length > 0) || manual) && filterable
    const showCustomHeader = selectedRows.size && customHeaderOnSelect
    const filteredData = this.getVisibleData()
    const showBulk = supportBulkEdit && selectedRows.size > 0
    const showDivider = (supportViews || customSelect || secondaryCustomSelect) && (showFilter || configurableColumns)
    const ThComponent = search && !filteredData.length ? this.noop : this.renderThComponent
    const isTableEmpty = (!data || data.length === 0) && (!filters.length && !hasInvalidFilters) && !search

    const tableStyle = {
      display: isSmallScreen ? 'block' : undefined,
      borderRadius: 0,
      border: 'none',
      height: '100%',
      minHeight: '500px',
      borderTop: filteredData.length ? `1px solid ${colors.grey3}` : 'none',
      ...style
    }

    const reactTable = <ReactTableFixedAndDragableColumns
      innerRef={(r) => (this.reactTable = r)}
      showPagination={false}
      resizable={resizable}
      defaultPageSize={Number.MAX_VALUE}
      pageSize={this.props.clientPaging ? this.state.rowsToShow : Number.MAX_VALUE}
      minRows={0}
      ThComponent={ThComponent}
      className={texts.body.toString()}
      style={tableStyle}
      getTheadThProps={this.injectTheadThSortOrder}
      getTheadProps={this.setTheadStyle}
      getTableProps={this.setTableStyle}
      getTheadGroupThProps={this.setTheadGroupThStyle}
      getTheadGroupProps={this.setTheadGroupStyle}
      getThProps={this.setThStyle}
      getTdProps={this.setTdStyle}
      getTrProps={this.getTrProps}
      getTrGroupProps={this.setTrGroupStyle}
      getResizerProps={this.setResizerProps}
      getNoDataProps={this.setNoDataProps}
      noDataText={this.noDataText()}
      onSortedChange={this.onSortedChange}
      defaultSorted={defaultSort}
      {...virtualizationProps}
      {...rest}
      sortable={sortable && !isReadOnlyView}
      manual={manual}
      data={filteredData}
      columns={loading ? this.getLoadingColumns(columns) : columns}
      draggableColumns={{
        mode: 'reorder',
        draggable: this.getDraggableColumns(columns),
        onDraggedColumnChange: this.onColumnOrderChange
      }}
      TbodyComponent={draggableRows ? ({ children }) => this.getTbodyComponent({ children, data: filteredData }) : undefined}
    />

    return (
      <div>
        {alternativeHeader || (
          <div onClick={onHeaderClick} className='tableHeader' {...css(CSS.tableHeader, tableHeaderStyle)}>
            <div {...CSS.filtersContainer}>
              {Header && <span {...CSS.tableHeaderTitle}>{Header}</span>}
              {supportViews && <TableViews tableKey={tableKey} onViewChanged={this.onViewChanged} disabled={isTableEmpty} editable={editableViews} />}
              {customSelect && <TableCustomSelect loading={loading} customSelectOptions={customSelectOptions} customSelectSelectedValue={customSelectSelectedValue} customSelectOptionRenderer={customSelectOptionRenderer} onChange={selectedOption => this.customSelectInternalOnChange({ selectedOption, isSecondaryCustomSelect: false })} />}
              {secondaryCustomSelect && <TableCustomSelect loading={loading} customSelectOptions={secondaryCustomSelectOptions} customSelectSelectedValue={secondaryCustomSelectSelectedValue} onChange={selectedOption => this.customSelectInternalOnChange({ selectedOption, isSecondaryCustomSelect: true })} />}
              {showDivider ? <Divider orientation='Horizontal' size={32} /> : null}
              {showFilter && <TableFilter tableKey={tableKey} onFiltersChange={this.onFiltersChange} optionsKey={filtersOptions} optionsValuesPerKey={filterOptionsValuesPerKey} filters={filters} disabled={isReadOnlyView || isTableEmpty} disabledReason={readOnlyTooltip} supportViews={supportViews} />}
              {configurableColumns && <ColumnsSelection tableKey={tableKey} options={configurableColumnsOptions} supportViews={supportViews} disabled={isReadOnlyView || isTableEmpty} disabledReason={readOnlyTooltip} selectableTable={selectable} />}
            </div>
            <div {...CSS.headerOptions}>
              {showBulk && <TableBulkEdit tableKey={tableKey} showDeleteButton={showBulkDelete} onDelete={onBulkDelete} fields={bulkEditFields} itemsName={itemsName} fieldKey={bulkEditFieldKey} fieldName={bulkEditFieldName} onSubmit={onBulkSubmit} selectedItems={Array.from(selectedRows)} clearSelection={this.clearSelection} allowedScopes={allowedScopes} />}
              {
                showCustomHeader ? customHeaderOnSelect(data)
                  : <>
                    {!showBulk && showSearch && <TableSearch disabled={isTableEmpty} onSearch={this.onSearch} searchValue={search} placeholder={searchPlaceholder} />}
                    {!showBulk && exportable && <TableCSVButton onClick={this.onExportToCSV} disabled={!data || data.length === 0} />}
                    {showImportCSV && <TableImportButton
                      onClick={this.onCSVUsersImport}
                      scopes={importCSVScopes}
                      popoverComponent={importCSVPopoverComponent}
                    />}
                    {this.getCustomButton()}
                    {extraHeaderComponent}
                  </>
              }
            </div>
          </div>
        )}
        { this.displayResults({ filterable, forceShowNumberOfResults, searchable, search, selectable, selectedRows, showCustomHeader, totalCount, filteredData, customSelect, loading, ignoreWhitespace }) }
        {alertMessage && <AlertBox type={AlertBoxType.INFORMATIVE} description={alertMessage} />}
        {!hideTableContent && reactTable}
        {((clientPaging && !loading && rowsToShow < (totalCount !== undefined ? totalCount : filteredData.length)) || forceShowSpinner) && <div {...CSS.loadingMore}><img alt='Loading...' src={Spinner} width='40px' height='40px' /></div>}
      </div>
    )
  }
}

Table.propTypes = {
  ...ReactTable.propTypes,
  data: PropTypes.array,
  columns: PropTypes.array,
  hideTableContent: PropTypes.bool,
  loading: PropTypes.bool,
  searchable: PropTypes.bool,
  searchFilterMethod: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  tableKey: PropTypes.string,
  striped: PropTypes.bool,
  setTrStyleClassName: PropTypes.string,
  overrideTrStyle: PropTypes.object,
  overrideTdStyle: PropTypes.object,
  overrideTheadStyle: PropTypes.object,
  overrideTrGroupStyle: PropTypes.object,
  virtualized: PropTypes.bool,
  emptyStateMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  filterable: PropTypes.bool,
  filters: PropTypes.array,
  filtersOptions: PropTypes.array,
  filterOptionsValuesPerKey: PropTypes.object,
  isFiltersOpen: PropTypes.bool,
  configurableColumns: PropTypes.bool,
  configurableColumnsOptions: PropTypes.array,
  exportable: PropTypes.bool,
  exportFunction: PropTypes.func,
  addColumnsToDefaultExport: PropTypes.func,
  exportPrefix: PropTypes.string,
  customButton: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
    tooltipMessage: PropTypes.string,
    isDisabled: PropTypes.bool,
    scopes: PropTypes.arrayOf(PropTypes.string)
  }),
  onSearch: PropTypes.func,
  forceShowSearch: PropTypes.bool,
  forceShowNumberOfResults: PropTypes.bool,
  fixedColumnOrder: PropTypes.bool,
  tableHeaderStyle: PropTypes.object,
  selectable: PropTypes.bool,
  onSelectChange: PropTypes.func,
  pageSize: PropTypes.number,
  onFilterChange: PropTypes.func,
  onSortedChangeCB: PropTypes.func,
  fetchData: PropTypes.func,
  fetchFieldValues: PropTypes.func,
  supportViews: PropTypes.bool,
  supportBulkEdit: PropTypes.bool,
  selectId: PropTypes.string,
  alertMessage: PropTypes.node,
  draggable: PropTypes.bool,
  scrollable: PropTypes.bool,
  editableViews: PropTypes.bool,
  shareViewPreferences: PropTypes.bool,
  onTrClick: PropTypes.func,
  onHeaderClick: PropTypes.func,
  onCSVUsersImport: PropTypes.func,
  importCSVScopes: PropTypes.arrayOf(PropTypes.string),
  draggableRows: PropTypes.bool,
  onRowsOrderChanged: PropTypes.func,
  scopesForDraggableRows: PropTypes.arrayOf(PropTypes.string),
  importCSVPopoverComponent: PropTypes.elementType,
  alternativeHeader: PropTypes.node
}

Table.defaultProps = {
  columns: [],
  resizable: true,
  searchable: false,
  exportable: false,
  searchFilterMethod: () => true,
  striped: false,
  setTrStyleClassName: '',
  virtualized: false,
  hideTableContent: false,
  onHeaderClick: () => {},
  filters: [],
  filtersOptions: [],
  filterOptionsValuesPerKey: {},
  configurableColumnsOptions: [],
  onSearch: () => {},
  forceShowSearch: false,
  forceShowNumberOfResults: false,
  fixedColumnOrder: false,
  selectable: false,
  onSelectChange: () => {},
  pageSize: 100,
  onFilterChange: () => {},
  onSortedChangeCB: () => {},
  fetchData: () => {},
  fetchFieldValues: () => {},
  supportViews: false,
  supportBulkEdit: false,
  selectId: 'id',
  sortable: true,
  draggable: true,
  editableViews: true,
  shareViewPreferences: false,
  onCSVUsersImport: () => {},
  importCSVScopes: [],
  draggableRows: false,
  scopesForDraggableRows: null
}

const Loader = () => <Placeholder loading style={{ padding: '10px 0', minWidth: 16 }}>{' '}</Placeholder>

const loadingData = range(10)

Table.numericFieldProps = {
  className: CSS.alignedField.toString(),
  headerClassName: CSS.alignedHeader.toString()
}

Table.sortMethods = {
  date: (a, b) => (moment(a || 0).isBefore(moment(b || 0)) ? -1 : 1),
  user: (usersById, a, b) => {
    const userA = getDisplayName({ ...(usersById[a] || {}), emptyValue: '' })
    const userB = getDisplayName({ ...(usersById[b] || {}), emptyValue: '' })
    return userA.localeCompare(userB)
  },
  users: (a, b) => {
    const userA = getDisplayName({ ...(a || {}), emptyValue: '' })
    const userB = getDisplayName({ ...(b || {}), emptyValue: '' })
    return userA.localeCompare(userB)
  },
  currency: (a, b) => (isNil(a) ? -1 : a) - (isNil(b) ? -1 : b)
}

export const sortColumns = (columns, userTablePreferences = {}) => {
  if (!userTablePreferences.sortedOnce) {
    return columns
  }

  const columnsOrder = userTablePreferences.columnsConfiguration || []
  return columns.sort((a, b) => {
    if (a.id === SELECTABLE_COLUMN_ID || b.id === SELECTABLE_COLUMN_ID) {
      return 0
    }
    if (a.id === DRAGGABLE_ROW_COLUMN_ID || b.id === DRAGGABLE_ROW_COLUMN_ID) {
      return 0
    }

    const aIndex = columnsOrder.indexOf(a.idForColumnsOrder || a.id || (isString(a.accessor) ? a.accessor : null))
    const bIndex = columnsOrder.indexOf(b.idForColumnsOrder || b.id || (isString(b.accessor) ? b.accessor : null))

    if (aIndex === -1 && bIndex === -1) {
      return 0
    }

    if (aIndex === -1) {
      return 1
    }

    if (bIndex === -1) {
      return -1
    }

    if (aIndex > bIndex) {
      return 1
    } else if (aIndex < bIndex) {
      return -1
    }

    return 0
  })
}

export default Table
