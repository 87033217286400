import React from 'react'
import PropTypes from 'prop-types'
import UserDetails from '../userDetails'
import AppDetails from '../appDetails'
import Analytics from '../../helpers/analytics'
import debouncePromise from 'debounce-promise'
import { ToriiSelectAsync } from '../select'

const MAX_RESULTS_AMOUNT_OF_ONE_TYPE = 5
export const optionTypes = {
  app: 'app',
  user: 'user',
  header: 'header'
}

class Search extends React.Component {
  navigateToPage = (selectedValue) => {
    const { history, idOrg } = this.props

    switch (selectedValue.optionType) {
      case optionTypes.user: {
        Analytics.track('Click on user', {
          Component: 'Global search',
          UserId: selectedValue.value,
          UserEmail: selectedValue.email
        })
        break
      }
      case optionTypes.app: {
        Analytics.track('Click on app', {
          Component: 'Global search',
          'App name': selectedValue.name
        })
        break
      }
      default: {
        break
      }
    }

    if (selectedValue.pathTemplate) {
      history.push(selectedValue.pathTemplate.replace('{idOrg}', idOrg))
    }
  }

  renderOption ({ data: item }) {
    let renderedOption
    switch (item.optionType) {
      case optionTypes.user: {
        renderedOption = <UserDetails
          overrideStyle={{ padding: 0, width: '100%' }}
          key={item.id}
          firstName={item.firstName}
          lastName={item.lastName}
          email={item.email}
          idUser={item.id}
          isExternal={item.isExternal}
          linkToUserPage
          showExternalUserBadge={false}
          photoUrl={item.photoUrl}
          lifecycleStatus={item.lifecycleStatus}
          isDeletedInIdentitySources={item.isDeletedInIdentitySources}
          showPastUserBadge
        />
        break
      }
      case optionTypes.app: {
        renderedOption = (
          <AppDetails
            overrideStyle={{ width: '100%' }}
            id={item.id}
            name={item.name}
            category={item.category}
            imageUrl={item.imageUrl}
            isNew={item.isNew}
            component='search'
          />
        )
        break
      }
      default: {
        break
      }
    }
    return renderedOption
  }

  onSearch = (searchValue) => {
    this.onSearchStopped(searchValue)
    return searchValue
  }

  onSearchStopped = (searchValue) => {
    if (!searchValue) {
      return
    }

    Analytics.track('Global search', {
      'Term': searchValue
    })
  }

  fetchData = debouncePromise(async (searchValue) => {
    const { idOrg, searchUsersAndApps, canSearchUsers } = this.props
    const type = canSearchUsers ? undefined : 'apps'
    const response = await searchUsersAndApps({ idOrg, q: searchValue, limit: MAX_RESULTS_AMOUNT_OF_ONE_TYPE, type })

    let usersHeaderWithOptions = []
    if (canSearchUsers) {
      const mappedUserOptions = response.results.users.map(user => ({ ...user, value: user.id, label: [user.firstName, user.lastName, user.email].join(' '), optionType: 'user', pathTemplate: `/team/{idOrg}/user/${user.id}` }))
      usersHeaderWithOptions = mappedUserOptions.length > 0 ? [{ label: 'Users', options: mappedUserOptions }] : []
    }

    const mappedAppOptions = response.results.apps.map(app => ({ ...app, value: app.id, label: app.name, optionType: 'app', pathTemplate: `/team/{idOrg}/app/${app.id}` }))
    const appsHeaderWithOptions = mappedAppOptions.length > 0 ? [{ label: 'Applications', options: mappedAppOptions }] : []
    return { optionsWithGroups: [...appsHeaderWithOptions, ...usersHeaderWithOptions] }
  }, 500)

  getOptions = async (searchValue) => {
    if (!searchValue) {
      return { optionsWithGroups: [] }
    }

    return this.fetchData(searchValue)
  }

  render () {
    const { isSmallScreen, canSearchUsers } = this.props
    const searchText = `Search ${canSearchUsers ? 'users or ' : ''}applications...`
    return (
      <ToriiSelectAsync
        icon='Search'
        selectWidth={350}
        optionRenderer={this.renderOption}
        onChange={this.navigateToPage}
        clearable={false}
        onInputChange={this.onSearch}
        placeholder={isSmallScreen ? 'Search' : searchText}
        noResultsText='No results found, please try again'
        components={{ DropdownIndicator: null }}
        loadOptions={this.getOptions}
        cache={false}
        loadingPlaceholder={'Loading...'}
        value={searchText}
        controlShouldRenderValue={false}
        autoBlur
        borderless
      />
    )
  }
}

Search.propTypes = {
  options: PropTypes.array
}

Search.defaultProps = {
  options: []
}

export default Search
