import React from 'react'
import { css } from 'glamor'
import moment from 'moment'
import {
  Avatar,
  Tooltip,
  ButtonType,
  Spacer,
  H4,
  Link,
  Body2,
  TextBadge,
  SelectComponents,
  Button,
  toast,
  ToastType
} from '@toriihq/design-system'
import { getDisplayName } from '@lenses/users'
import Table from '../table'
import config from '../../config'
import { hoverActions } from '../../shared/style/mixins'
import { DATE_FORMAT, SCOPES, TABLES } from '../../constants'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import SelectionBox from '../workflows/selectionBox'
import ToriiPopup from '../popups/ToriiPopupV2'
import DatePicker from '@components/datePicker'
import FormGroup from '@components/form/formGroup'
import Input from '@components/form/input'
import Select from '@components/select'
import { getFormattedDate } from '@lenses/utils'
import ButtonActions from '@components/_shared/buttonActions'
import Confirmation from '@components/confirmation'
import Analytics from '@helpers/analytics'

const CSS = {
  subHeaderDescription: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  deleteIcon: css({
    textAlign: 'right'
  }),
  fullWidth: css({
    width: '100%'
  })
}

const PUBLIC_API_KEY_PERMISSION = {
  READ: 'read',
  READ_WRITE: 'read_write',
  SCIM: 'scim'
}

const PUBLIC_API_KEY_PERMISSION_DISPLAY_NAME = {
  [PUBLIC_API_KEY_PERMISSION.READ]: 'Read only',
  [PUBLIC_API_KEY_PERMISSION.READ_WRITE]: 'Full access'
}

const API_KEY_TYPES = {
  torii: { label: 'Torii API', value: 'torii' },
  scim: { label: 'SCIM', value: 'scim' }
}

class ApiKeys extends React.Component {
  state = {
    apiKey: {},
    permission: PUBLIC_API_KEY_PERMISSION.READ_WRITE,
    type: API_KEY_TYPES.torii.value,
    expirationTime: moment.utc().add(1, 'year').format('YYYY-MM-DD').toString(),
    description: null,
    showCreateApiKey: false,
    showCreatedApiKey: false,
    showDeleteConfirmation: false,
    columns: this.getColumns()
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.apiKeys !== prevProps.apiKeys || this.props.userMap !== prevProps.userMap) {
      this.setState({ columns: this.getColumns() })
    }
    if (prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  getColumns () {
    return [
      {
        Header: 'API key',
        accessor: 'id',
        Cell: ({ value: id }) => (
          <Tooltip
            placement='top'
            label='The API Key is secret. If you do not have access to it, generate a new one.'>
            {id}-########-####-####-####-############
          </Tooltip>
        ),
        maxWidth: 400,
        width: 340
      },
      {
        id: 'action',
        Header: '',
        sortable: false,
        className: css(CSS.deleteIcon, !this.props.isSmallScreen && hoverActions).toString(),
        Cell: ({ row: { id } }) => (
          <ButtonActions
            showDeleteAction
            onDeleteClick={() => this.openDeleteConfirm(id)}
            enableForScopes={[SCOPES.API_MANAGEMENT_WRITE]}
          />
        )
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ value: idUser }) => {
          const user = this.props.userMap[idUser] || {}
          const { firstName, lastName, photoUrl } = user
          return (
            <Tooltip label={getDisplayName(user)}>
              <RelativeTeamUserLink idUser={idUser}>
                <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
              </RelativeTeamUserLink>
            </Tooltip>
          )
        },
        maxWidth: 130
      },
      {
        Header: 'Created at',
        accessor: 'creationTime',
        Cell: ({ value: date }) => moment.utc(date).format(DATE_FORMAT),
        maxWidth: 250,
        sortMethod: Table.sortMethods.date
      },
      {
        Header: 'Expires at',
        accessor: 'expirationTime',
        Cell: ({ value: date }) => {
          if (!date) {
            return 'Never expires'
          }
          const displayDate = getFormattedDate({ date, emptyValueDefaultDisplay: 'Never expires' })
          const isExpired = moment.utc().isAfter(date)
          if (!isExpired) {
            return displayDate
          }

          return <>
            <span>{displayDate} <TextBadge color='gray' size='Small'>Expired</TextBadge></span>
          </>
        },
        width: 220,
        sortMethod: Table.sortMethods.date
      },
      {
        Header: 'Type',
        id: 'type',
        accessor: ({ permission }) => permission === 'scim' ? 'SCIM' : 'Torii API'
      },
      {
        Header: 'Permission',
        id: 'permission',
        accessor: ({ permission }) => permission === 'scim' ? PUBLIC_API_KEY_PERMISSION_DISPLAY_NAME[PUBLIC_API_KEY_PERMISSION.READ_WRITE] : PUBLIC_API_KEY_PERMISSION_DISPLAY_NAME[permission] || '-'
      },
      {
        Header: 'Description',
        accessor: 'description'
      }
    ]
  }

  fetchData () {
    const { idOrg, getApiKeys } = this.props

    getApiKeys({ idOrg })
  }

  createApiKey = async () => {
    const { idOrg, createApiKey, getApiKeys } = this.props
    const { type, permission, expirationTime, description } = this.state
    const { apiKey } = await createApiKey({ idOrg, permission: type === PUBLIC_API_KEY_PERMISSION.SCIM ? PUBLIC_API_KEY_PERMISSION.SCIM : permission, expirationTime, description })
    await getApiKeys({ idOrg })
    this.closeCreateAndOpenCreated({ apiKey })
  }

  openCreate = () => {
    this.setState({ showCreateApiKey: true })
  }

  closeCreate = () => {
    this.setState({
      showCreateApiKey: false,
      description: null,
      type: API_KEY_TYPES.torii.value,
      permission: PUBLIC_API_KEY_PERMISSION.READ_WRITE,
      expirationTime: moment.utc().add(1, 'year').format('YYYY-MM-DD').toString()
    })
  }

  closeCreateAndOpenCreated = ({ apiKey }) => {
    this.setState({
      showCreateApiKey: false,
      type: API_KEY_TYPES.torii.value,
      permission: PUBLIC_API_KEY_PERMISSION.READ_WRITE,
      expirationTime: moment.utc().add(1, 'year').format('YYYY-MM-DD').toString(),
      description: null,
      showCreatedApiKey: true,
      apiKey
    })
  }

  closeCreateConfirm = () => {
    this.setState({ showCreatedApiKey: false, apiKey: {} })
  }

  openDeleteConfirm (id) {
    this.setState({ showDeleteConfirmation: true, idKeyToDelete: id })
  }

  closeDeleteConfirm = () => {
    this.setState({ showDeleteConfirmation: false, idKeyToDelete: null })
  }

  onPermissionChanged = ({ value }) => {
    this.setState({ permission: value })
  }

  onTypeChanged = ({ value }) => {
    if (value === API_KEY_TYPES.scim.value) {
      this.setState({ type: value, permission: PUBLIC_API_KEY_PERMISSION.READ_WRITE })
    } else {
      this.setState({ type: value })
    }
  }

  onExpirationTimeChange = (value) => {
    this.setState({ expirationTime: value })
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value })
  }

  onDeleteKey = async (id) => {
    const { deleteApiKey, getApiKeys, idOrg } = this.props
    await deleteApiKey({ idOrg, id })
    this.closeDeleteConfirm()
    return getApiKeys({ idOrg })
  }

  getPermissionOptions = ({ disableRead }) => {
    return [
      {
        name: 'Choose permission',
        options: [
          {
            value: PUBLIC_API_KEY_PERMISSION.READ_WRITE,
            label: PUBLIC_API_KEY_PERMISSION_DISPLAY_NAME[PUBLIC_API_KEY_PERMISSION.READ_WRITE]
          },
          {
            value: PUBLIC_API_KEY_PERMISSION.READ,
            label: PUBLIC_API_KEY_PERMISSION_DISPLAY_NAME[PUBLIC_API_KEY_PERMISSION.READ],
            isDisabled: disableRead
          }
        ]
      }
    ]
  }

  copyToClipboard = async () => {
    Analytics.track('Click on Copy API key')

    await navigator.clipboard.writeText(this.state.apiKey.token)
    toast({
      message: `API key was copied to clipboard`,
      type: ToastType.SUCCESS
    })
  }

  render () {
    const { loading, apiKeys, apiKeysConfig, isScimEnabled } = this.props
    const apiReferenceUrl = `${config.apiBaseUrl}/v1.0/docs`
    const scimReferenceUrl = `${apiReferenceUrl}#tag-SCIM`
    const helpArticleUrl = 'https://support.toriihq.com/hc/en-us/articles/9183460072347'
    const excludeFromFullStory = 'fs-exclude'

    return (
      <div>
        <Spacer bottom={'space-100'}><H4>API Access</H4></Spacer>
        <Body2>
          Manage your organization's API keys. An API key is required in order to use <Link href={apiReferenceUrl} target='_blank'>Torii APIs</Link> and <Link href={scimReferenceUrl} target='_blank'>Torii SCIM APIs</Link>.
          <br />
          <Link href={helpArticleUrl} target='_blank'>Learn more about API access</Link>
        </Body2>
        <Table
          tableKey={TABLES.apiKeysTable.key}
          data={apiKeys}
          columns={this.state.columns}
          loading={loading}
          customButton={{
            onClick: this.openCreate,
            text: 'Generate API key',
            scopes: [SCOPES.API_MANAGEMENT_WRITE]
          }}
        />
        <ToriiPopup isOpen={this.state.showCreateApiKey} onCloseAction={this.closeCreate}>
          <ToriiPopup.Header header={'Generate API Key'} />
          <ToriiPopup.Content>
            <FormGroup label='Choose type' isRequired>
              <Select
                options={Object.values(API_KEY_TYPES).map((type) => {
                  const shouldDisableScim = type.value === 'scim' && !isScimEnabled
                  return { ...type, description: shouldDisableScim ? 'To select this option please enable SCIM in Settings -> Security' : '', disabled: shouldDisableScim }
                })}
                value={this.state.type}
                onChange={this.onTypeChanged}
                clearable={false}
                components={{ Option: SelectComponents.OptionWithDescription }}
                isOptionDisabled={option => option.disabled ?? false}
              />
            </FormGroup>
            <div style={{ marginBottom: 24 }}>
              <SelectionBox
                selectedValue={this.state.permission}
                groups={this.getPermissionOptions({ disableRead: this.state.type === 'scim' })}
                onChange={this.onPermissionChanged}
                display='row'
                allowedScopes={[SCOPES.API_MANAGEMENT_WRITE]}
              />
            </div>
            <FormGroup label='Expiration date' isRequired>
              <DatePicker
                format={'MMMM DD, YYYY'}
                value={moment(this.state.expirationTime).format('MMMM DD, YYYY').toString()}
                onDayChange={this.onExpirationTimeChange}
                placeholder={moment.utc().add(apiKeysConfig.minExpirationDays, 'day')}
                position='top'
                dayPickerProps={{
                  disabledDays: {
                    before: moment.utc().add(apiKeysConfig.minExpirationDays, 'day').toDate(),
                    after: moment.utc().add(apiKeysConfig.maxExpirationDays, 'day').toDate()
                  }
                }}
              />
            </FormGroup>
            <FormGroup label='Description' isRequired>
              <Input
                maxLength={apiKeysConfig.maxDescriptionLength}
                value={this.state.description || ''}
                onChange={this.onDescriptionChange}
              />
            </FormGroup>
          </ToriiPopup.Content>
          <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonText='Generate key'
            isMainButtonDisabled={!this.state.expirationTime || !this.state.description}
            mainButtonAction={this.createApiKey}
          />
        </ToriiPopup>

        <ToriiPopup isOpen={this.state.showCreatedApiKey} onCloseAction={this.closeCreateConfirm}>
          <ToriiPopup.Header header={'API Key'} />
          <ToriiPopup.Content>
            <div className={excludeFromFullStory}>
              Make sure to copy your new API Key now. You won’t be able to see it again!
              <br />
              <br />
              Your API key is:
              <br />
              <Input
                readOnly
                value={this.state.apiKey.token || ''}
                suffix={<Button type={ButtonType.compact} icon='Copy' onClick={this.copyToClipboard} />}
              />
            </div>
          </ToriiPopup.Content>
          <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonText='Got it'
            mainButtonAction={this.closeCreateConfirm}
          />
        </ToriiPopup>
        <Confirmation
          isOpen={this.state.showDeleteConfirmation}
          header='Delete API Key'
          text='Are you sure you want to delete the API key?'
          confirmText='Delete'
          confirm={() => this.onDeleteKey(this.state.idKeyToDelete)}
          close={this.closeDeleteConfirm}
          declineText='Cancel'
          decline={this.closeDeleteConfirm}
          mainButtonType={ButtonType.destructive}
        />
      </div>
    )
  }
}

export default ApiKeys
