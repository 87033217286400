import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/es'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { isEmpty, last, omitBy } from 'lodash'
import { getRenewalsSummary, isLoadingRenewalsSummary } from '@selectors/contracts'
import Select from '@components/select'
import FormGroup from '@components/form/formGroup'
import moment from 'moment'
import RenewalsListTable from '@components/renewalsSummary/renewalsListTable'
import {
  EmptyStateContainer,
  FlexColumnDiv,
  FlexRowDiv,
  StyledPlaceholder,
  StyledPlaceholderContainer,
  TableWrapper
} from '@components/renewalsSummary/styles'
import { bucketSummarySortOrder } from '@components/renewalsSummary/utils/utils'
import { getUserPreferences } from '@selectors/ui'
import { renewalsSummaryBaseYearChange } from '@shared/actions/ui'
import WaterfallChart from '@components/waterfallChartBox'
import { Body2, Button, EmptyState, H4, Link, Spacer } from '@toriihq/design-system'
import reportSVG from '@media/report.svg'
import { toggleContractPopup } from '@shared/actions'
import { calculateGraphData } from '@components/renewalsSummary/utils/calculateGraphData'
import { barsConfig, legendMapping } from '@components/renewalsSummary/utils/graphConfigs'
import { CustomBar, CustomizedLabel, renderToolTip } from '@components/renewalsSummary/utils/graphCustomComponents'
import Currency from '@components/currency'
import Analytics from '@components/renewalsSummary/analytics'
import { RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS } from '@root/constants'

const RenewalsSummary = () => {
  const dispatch = useDispatch()
  let renewalsSummary = useSelector(getRenewalsSummary)
  const isLoading = useSelector(isLoadingRenewalsSummary)
  const userPreferences = useSelector(getUserPreferences)
  const baseYearPreference = userPreferences?.renewalsSummary?.baseYear

  const currentYear = moment().year()
  renewalsSummary = omitBy(renewalsSummary, (value, year) => parseInt(year) > currentYear)

  const [baseYear, setBaseYear] = useState(baseYearPreference || { value: 0, label: '' })
  const [bucketOption, setBucketOption] = useState(RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS.all)

  useDeepCompareEffect(() => {
    if (renewalsSummary) {
      const lastAvailableYear = last(Object.keys(renewalsSummary))
      if (lastAvailableYear && (!baseYear.value || !renewalsSummary[baseYear.value])) {
        onYearChange({ value: Number(lastAvailableYear), label: lastAvailableYear })
      }
    }
  }, [renewalsSummary])

  const baseYearRenewals = (renewalsSummary[baseYear.value] || []).sort((a, b) => bucketSummarySortOrder[a.bucket] - bucketSummarySortOrder[b.bucket])

  const onYearChange = (year) => {
    setBaseYear(year)
    dispatch(renewalsSummaryBaseYearChange({ baseYear: year }))
    Analytics.selectReportYear(year.value)
  }

  const onBucketChange = (filterMethod) => (bucket) => {
    setBucketOption(bucket)
    Analytics.filterTableView(bucket.label, filterMethod)
  }

  const LearnMoreLink = <Link href='https://support.toriihq.com/hc/en-us/articles/27435239563675-Renewals-Summary-Report' target='_blank' >Learn more</Link>

  const emptyState =
    <EmptyStateContainer>
      <EmptyState
        orientation='vertical'
        align='start'
        image={<img src={reportSVG} alt='no-renewals-summary' />}
        title='Add contracts to view report'
        description='The yearly summary report helps you review past and upcoming contract renewals, analyze savings, and monitor rising app costs. Add your contracts to start tracking.'
        buttons={[
          <Button key='a' onClick={() => dispatch(toggleContractPopup({ isOpen: true, isDuplicated: false, idApp: null, idContract: null, buttonLabel: null, initialValues: null, onSuccessCB: null }))} label='Add Contracts' />
        ]}
        link={LearnMoreLink}
      />
    </EmptyStateContainer>

  return (!isLoading && isEmpty(renewalsSummary)) ? emptyState : (
    <StyledPlaceholderContainer>
      <StyledPlaceholder loading={isLoading} type='rect'>
        <FlexRowDiv>
          <FlexColumnDiv>
            <Spacer bottom={'space-100'}>
              <H4>
                Renewals Yearly Summary
              </H4>
            </Spacer>
            <Body2>
              Get a birds eye view of your renewals for the selected year as well as aggregated savings.
            </Body2>
          </FlexColumnDiv>
          <div>
            <FormGroup
              label='Show report for year'
            >
              <Select
                options={Object.keys(renewalsSummary).map(year => ({ value: Number(year), label: year })).reverse()}
                placeholder={<div>Select year</div>}
                value={baseYear}
                onChange={onYearChange}
                clearable={false}
                searchable={false}
                key='renewals_summary_base_year_select'
                name='renewals_summary_base_year_select'
                openOnFocus
                selectWidth={225}
              />
            </FormGroup>
          </div>
        </FlexRowDiv>
        <div>
          <WaterfallChart
            data={calculateGraphData({ baseYear, baseYearRenewals })}
            chartHeight={320}
            tooltipContent={renderToolTip(baseYear)}
            analyticsEvent={undefined}
            barsConfig={barsConfig({ CustomBar: CustomBar(baseYear), CustomizedLabel, onBucketChange: onBucketChange('Click on chart') })}
            legendMapping={legendMapping}
            formatter={(value) => <Currency value={value * 100} />}
          />
        </div>
        <TableWrapper>
          <RenewalsListTable baseYearRenewals={baseYearRenewals} baseYear={baseYear} bucketOption={bucketOption} onBucketChange={onBucketChange('Filter dropdown')} />
        </TableWrapper>
      </StyledPlaceholder>
    </StyledPlaceholderContainer>
  )
}

export default RenewalsSummary
