import React, { ReactElement, useEffect, useState } from 'react'
import { APP_PERMISSIONS, SCOPES } from '@root/constants'
import FormGroup from '@components/form/formGroup'
import Placeholder from '@components/placeholder'
import EnableFor from '@components/enableFor'
import { Icon, Link, LinkSize, Stack, Subtitle1, toast, ToastType, Tooltip } from '@toriihq/design-system'
import { getConnectedServicesByIdApp, isServicesLoading as isServicesLoadingSelector } from '@selectors/services'
import { useSelector, useDispatch } from 'react-redux'
import { SOURCE_TYPES } from '@root/sourcesConfig'
import ToggleOfFeature from '@components/toggleOfFeature'
import Analytics from '@helpers/analytics'
import { FEATURES } from '@shared/features'
import Confirmation from '@components/confirmation'
import { getAppsV2 } from '@selectors/apps'
import { getAppsV2 as getAppsV2Action } from '@shared/actions'
import { ops } from '@root/lenses/filters'
import { pluralizeWithFormat } from '@root/shared/utils'
import { placeholderStyle } from './../style'
import * as Style from '@components/orgGeneralSettings/style'

interface Props {
  loading: boolean
  isBlockThirdPartyAppsEnabled: boolean
  onBlockAccessToggle: (enabled: boolean) => void
  idOrg: number
}

const BlockAccessToggle = ({ loading, isBlockThirdPartyAppsEnabled, onBlockAccessToggle, idOrg }: Props): ReactElement => {
  const googleServices = useSelector(getConnectedServicesByIdApp)[SOURCE_TYPES.google.idApp] ?? []
  const isServicesLoading = useSelector(isServicesLoadingSelector)
  const dispatch = useDispatch()
  const apps = useSelector(getAppsV2)
  const closedApps = apps?.apps.filter(app => app.state === 'Closed') || []

  const [toggleConfirmation, setToggleConfirmation] = useState({
    isOpen: false,
    toggleAction: () => {}
  })

  useEffect(() => {
    if (!idOrg) {
      return
    }

    dispatch(getAppsV2Action({ idOrg, filters: [{ key: 'state', op: ops.equals, value: 'closed' }] } as any))
  }, [idOrg, dispatch])

  const getTooltipLabel = () => {
    const connectedServices = googleServices.filter(service => service.isConnected)
    if (connectedServices.length === 0) {
      return 'No Google Workspace integration is connected.'
    } else if (connectedServices.length === 1 && connectedServices[0].permission === APP_PERMISSIONS.read) {
      return 'Google Workspace integration is connected with read only permissions. Data access cannot be revoked.'
    } else if (connectedServices.find(service => service.permission === APP_PERMISSIONS.read)) {
      return 'Some Google accounts are connected with read only scope. The data access for these accounts cannot be revoked.'
    }

    return ''
  }

  const tooltipLabel = getTooltipLabel()
  const shouldShowTooltip = tooltipLabel && !loading && !isServicesLoading

  const getToastByState = (isEnabled: boolean) => {
    if (isEnabled) {
      const connectedServices = googleServices.filter(service => service.isConnected)
      if (connectedServices.length === 0) {
        return { type: ToastType.WARNING, message: 'No Google Workspace integration is connected. The configuration cannot be applied yet.' }
      } else if (connectedServices.length === 1 && connectedServices[0].permission === APP_PERMISSIONS.read) {
        return { type: ToastType.WARNING, message: 'Google Workspace integration is connected with read only permissions. Data access cannot be revoked.' }
      } else if (connectedServices.find(service => service.permission === APP_PERMISSIONS.read)) {
        return { type: ToastType.WARNING, message: 'Some Google accounts are connected with read only scope. The data access for these accounts cannot be revoked.' }
      }

      return { type: ToastType.SUCCESS, message: 'Permissions for all applications in the closed state are being revoked. It can take several minutes.' }
    }

    return { type: ToastType.WARNING, message: 'Sensitive organizational data can now be accessed by closed applications.' }
  }

  const showToggleToast = ({ isEnabled }: { isEnabled: boolean }) => {
    onBlockAccessToggle(isEnabled)
    const toastData = getToastByState(isEnabled)
    toast(toastData)
  }

  const onToggle = (isEnabled: boolean) => {
    Analytics.track('Activate block-access-for-3d-party-apps', { State: +isEnabled })

    if (!isEnabled) {
      return showToggleToast({ isEnabled: false })
    }

    setToggleConfirmation({
      isOpen: true,
      toggleAction: () => {
        Analytics.track('Click on revoke-permissions-button')
        showToggleToast({ isEnabled: true })
        closeConfirmation()
      }
    })
  }

  const onCancelConfirm = () => {
    Analytics.track('Close revoke-permissions-dialog', {
      'Button label': 'Cancel'
    })
    closeConfirmation()
  }

  const onCloseConfirm = () => {
    Analytics.track('Close revoke-permissions-dialog', {
      'Button label': 'X'
    })
    closeConfirmation()
  }

  const closeConfirmation = () => {
    setToggleConfirmation({
      isOpen: false,
      toggleAction: () => {}
    })
  }

  const getNumOfClosedApps = () => {
    return closedApps?.length || 0
  }

  return (<>
    <Stack gap='space-100'>
      <Stack direction='row' gap='space-050' alignItems='center'>
        <Subtitle1>Block access to sensitive data for third-party apps</Subtitle1>
        {shouldShowTooltip
          ? <Tooltip label={tooltipLabel}>
            <Icon name='Alert' color='warning' />
          </Tooltip>
          : null}
        <div style={{ marginLeft: 'auto' }}>
          <Link
            size={LinkSize.Medium}
            href='https://support.toriihq.com/hc/en-us/articles/27307369999387-Monitor-third-party-apps-and-block-access-to-sensitive-data'
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => Analytics.track('Click on learn-more-link-block-access')}
          >
            Learn More
          </Link>
        </div>
      </Stack>
      <FormGroup>
        <div {...Style.benchmarkTitlesAndToggle}>
          <div>Revoke data access permissions from apps in the Closed state (Google only)</div>
          <Placeholder loading={loading} type='rect' style={placeholderStyle}>
            <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
              <ToggleOfFeature
                feature={FEATURES.THIRD_PARTY_APPS.BLOCK_ACCESS}
                data-testid='blockAccessToggle'
                checked={Boolean(isBlockThirdPartyAppsEnabled)}
                tooltipPlacement={'top'}
                onToggle={onToggle}
                id='blockAccessToggle'
              />
            </EnableFor>
          </Placeholder>
        </div>
      </FormGroup>
    </Stack>

    <Confirmation
      isOpen={toggleConfirmation.isOpen}
      confirm={toggleConfirmation.toggleAction}
      decline={onCancelConfirm}
      close={onCloseConfirm}
      header={'Revoke data access permissions?'}
      text={<>
        <div>
          You are about to remove permissions from {pluralizeWithFormat('app', getNumOfClosedApps(), true)}.
        </div>
        <div>
          Users will still be able to access the apps, but their organizational data will no longer be shared.
        </div>
      </>}
      modalWidth={'518px'}
      confirmText='Revoke'
      declineText='Cancel'
    />
  </>
  )
}

export default BlockAccessToggle
