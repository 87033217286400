import React from 'react'
import PropTypes from 'prop-types'
import { DATE_FORMAT, formFieldTypes } from '../../../constants'
import moment from 'moment'
import { getDisplayName } from '../../../lenses/users'
import EditableField from '@components/editableField'
import Select from '../../select'
import MultipleCheckboxSelect from '../../multipleCheckboxSelect'
import noop from 'lodash/noop'
import isUndefined from 'lodash/isUndefined'
import UserDetails from '../../userDetails'
import isNil from 'lodash/isNil'
import SelectUsers from '@components/selectUsers'
import { formatDateAsUTC } from '@shared/utils'
import { round } from 'lodash'
import Currency from '@components/currencyOld'
import { DatePicker } from '@toriihq/design-system'

const simpleEmailRegex = /^\S+@\S+\.\S+/
const validateEmail = (value) => simpleEmailRegex.test(value)

class FieldByType extends React.Component {
  renderOption = (item) => {
    return <UserDetails {...item} overrideStyle={{ width: '100%' }} />
  }

  render () {
    const { field, readonly, disabled, identifier, label, value, onSave, onRemove, onHistory, showHistoryButton, currencySymbol, loading, usersById } = this.props
    const basicProps = {
      readonly,
      disabled,
      key: identifier,
      label,
      value: isNil(value) ? '' : value,
      onSave,
      onRemove,
      onHistory,
      showHistoryButton: showHistoryButton && !isUndefined(value)
    }
    switch (field.type) {
      case formFieldTypes.singleLine: {
        return (
          <EditableField
            {...basicProps}
          />
        )
      }
      case formFieldTypes.email: {
        return (
          <EditableField
            {...basicProps}
            validate={validateEmail}
          />
        )
      }
      case formFieldTypes.number: {
        return (
          <EditableField
            {...basicProps}
            parse={value => value && !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : value}
            validate={value => value && !isNaN(parseInt(value, 10)) && parseInt(value, 10) >= 0}
          />
        )
      }
      case formFieldTypes.currency: {
        return (
          <EditableField
            {...basicProps}
            value={value ? (value / 100).toString() : null}
            validate={value => value && !isNaN(parseFloat(value, 10))}
            displayFormat={value => <Currency value={value} currencySymbol={currencySymbol} format={'$0,0.[00]'} />}
            onSave={value => {
              value = value.replace(/,/g, '')
              onSave(round(parseFloat(value, 10) * 100))
            }}
          />
        )
      }
      case formFieldTypes.cardNumber: {
        return (
          <EditableField
            {...basicProps}
            value={value ? value.toString() : value}
            prefix={'XXXX XXXX XXXX '}
            parse={value => value.substr(0, 4)}
            validate={value => value && value.length === 4}
            overrideStyle={{ ' .value': { direction: 'rtl' } }}
          />
        )
      }
      case formFieldTypes.datePicker: {
        return (
          <EditableField
            {...basicProps}
            value={value ? new Date(formatDateAsUTC(value)) : ''}
            displayFormat={value => value ? moment(value).format(DATE_FORMAT) : ''}
            format={value => value}
          >
            {({ isEditMode, onChange, value, onSave }) =>
              isEditMode
                ? (
                  <DatePicker
                    onDayPickerHide={onSave}
                    value={value ? moment(value).format('MM/DD/YYYY') : value}
                    inputProps={{ autoFocus: true }}
                    onDayChange={(date) => {
                      onChange(date && moment.utc(date, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                    }}
                  />
                )
                : null
            }
          </EditableField>
        )
      }
      case formFieldTypes.multiLine: {
        return (
          <EditableField
            {...basicProps}
            input='textarea'
          />
        )
      }
      case formFieldTypes.dropdown: {
        return (
          <EditableField
            {...basicProps}
            format={value => (field.options.find(item => item.value === value) || {}).label}
          >
            {({ isEditMode, onChange, onSave, value }) =>
              isEditMode
                ? (
                  <Select
                    ref={select => select && select.open && select.open()}
                    options={field.options.filter(option => !option.isDeleted)}
                    labelKey='label'
                    valueKey='value'
                    clearable={false}
                    searchable={false}
                    name='role'
                    onChange={selection => {
                      onChange(selection.value)
                      setTimeout(onSave, 0)
                    }}
                    autoFocus
                    openOnFocus
                  />
                )
                : null}
          </EditableField>
        )
      }
      case formFieldTypes.dropdownMulti: {
        const format = (values) => (values || [])
          .map(value => (field.options.find(item => item.value === value) || {}).label)
          .join(', ')

        return (
          <EditableField
            {...basicProps}
            value={field.values || basicProps.value}
            format={format}
          >
            {({ isEditMode, onChange, value }) =>
              isEditMode
                ? (
                  <MultipleCheckboxSelect
                    ref={select => select && select.open && select.open()}
                    options={field.options.filter(option => !option.isDeleted)}
                    selectedValues={value}
                    isLoading={loading}
                    onChange={onChange}
                    autoFocus
                    showValues
                  />
                )
                : null}
          </EditableField>
        )
      }
      case formFieldTypes.usersDropdown: {
        return (
          <EditableField
            {...basicProps}
            format={value => usersById[value] && getDisplayName(usersById[value])}
          >
            {({ isEditMode, onChange, onSave, value }) =>
              isEditMode
                ? (
                  <SelectUsers
                    placeholder='Search users'
                    autoFocus
                    openOnFocus
                    onChange={user => {
                      if (user && !usersById[user.id]) {
                        usersById[user.id] = user
                      }
                      onChange(user && user.id)
                      setTimeout(onSave, 0)
                    }}
                    value={usersById[value]}
                  />
                )
                : null}
          </EditableField>
        )
      }
      case formFieldTypes.usersDropdownMulti: {
        return (
          <EditableField
            {...basicProps}
            value={field.values || basicProps.value}
            format={values => values && [].concat(values).map(v => usersById[v] && getDisplayName(usersById[v])).join(', ')}
          >
            {({ isEditMode, onChange }) =>
              isEditMode
                ? (
                  <SelectUsers
                    placeholder='Search users'
                    autoFocus
                    openOnFocus
                    multi
                    onChange={users => {
                      users.forEach(user => {
                        if (user && !usersById[user.id]) {
                          usersById[user.id] = user
                        }
                      })
                      users && onChange(users.map(u => u && u.id))
                    }}
                    value={(field.values ?? (value || [])).map(id => usersById[id])}
                  />
                )
                : null
            }
          </EditableField>
        )
      }
      case formFieldTypes.contractsDropdownMulti: {
        return (
          <EditableField
            {...basicProps}
            format={value => {
              const values = Object.values(value)
              return field.options.filter(option => values.includes(option.value)).map(option => option.label).join(', ')
            }}
          >
            {({ isEditMode, onChange, onSave, value: values }) => {
              return isEditMode
                ? (
                  <Select
                    multi
                    value={values}
                    ref={select => select && select.open && select.open()}
                    options={field.options}
                    clearable={false}
                    searchable={false}
                    searchPromptText={field.searchPromptText}
                    onChange={selections => {
                      onChange(selections.map(contract => contract.value))
                      setTimeout(onSave, 0)
                    }}
                    autoFocus
                  />
                )
                : null
            }
            }
          </EditableField>
        )
      }
      default: {
        return null
      }
    }
  }
}

FieldByType.propTypes = {
  currencySymbol: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  userMap: PropTypes.object.isRequired,
  readonly: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  identifier: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number, PropTypes.array]),
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onHistory: PropTypes.func.isRequired,
  showHistoryButton: PropTypes.bool.isRequired,
  field: PropTypes.object.isRequired
}

FieldByType.defaultProps = {
  onHistory: noop,
  onSave: noop,
  onRemove: noop,
  showHistoryButton: false,
  readonly: false,
  disabled: false,
  users: [],
  userMap: {},
  field: {}
}

export default FieldByType
